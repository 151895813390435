/*
* Noto Sans KR (korean) http://www.google.com/fonts/earlyaccess
 */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url(./woff/NotoSansKR-Thin-Hestia.woff) format('woff'),
  url(./otf/NotoSansKR-Thin-Hestia.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url(./woff/NotoSansKR-Light-Hestia.woff) format('woff'),
  url(./otf/NotoSansKR-Light-Hestia.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url(./woff/NotoSansKR-Regular-Hestia.woff) format('woff'),
  url(./otf/NotoSansKR-Regular-Hestia.otf) format('opentype');
 }

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url(./woff/NotoSansKR-Medium-Hestia.woff) format('woff'),
  url(./otf/NotoSansKR-Medium-Hestia.otf) format('opentype');
 }

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url(./woff/NotoSansKR-Bold-Hestia.woff) format('woff'),
  url(./otf/NotoSansKR-Bold-Hestia.otf) format('opentype');
 }

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url(./woff/NotoSansKR-Black-Hestia.woff) format('woff'),
  url(./otf/NotoSansKR-Black-Hestia.otf) format('opentype');
 }