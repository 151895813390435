*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  font-size: 12px;
  line-height: 1.42857143;
  color: #949494;
  background-color: #ffffff;
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
a {
  color: #373e4a;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #818da2;
}
a:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
img {
  vertical-align: middle;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.img-rounded {
  border-radius: 3px;
}
.img-thumbnail {
  padding: 2px;
  line-height: 1.42857143;
  background-color: #ffffff;
  border: 1px solid #ededf0;
  border-radius: 3px;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.img-circle {
  border-radius: 50%;
}
hr {
  margin-top: 17px;
  margin-bottom: 17px;
  border: 0;
  border-top: 1px solid #eeeeee;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
figure {
  margin: 0;
}
p {
  margin: 0 0 8.5px;
  font-size: 12px;
}
.lead {
  margin-bottom: 17px;
  font-size: 13px;
  font-weight: 200;
  line-height: 1.4;
}
@media (min-width: 768px) {
  .lead {
    font-size: 18px;
  }
}
small,
.small {
  font-size: 85%;
}
cite {
  font-style: normal;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-muted {
  color: #999999;
}
.text-primary {
  color: #949494;
}
a.text-primary:hover {
  color: #7b7b7b;
}
.text-success {
  color: #045702;
}
a.text-success:hover {
  color: #022501;
}
.text-info {
  color: #2c7ea1;
}
a.text-info:hover {
  color: #215f79;
}
.text-warning {
  color: #574802;
}
a.text-warning:hover {
  color: #251f01;
}
.text-danger {
  color: #ac1818;
}
a.text-danger:hover {
  color: #7f1212;
}
.bg-primary {
  color: #fff;
  background-color: #949494;
}
a.bg-primary:hover {
  background-color: #7b7b7b;
}
.bg-success {
  background-color: #bdedbc;
}
a.bg-success:hover {
  background-color: #95e294;
}
.bg-info {
  background-color: #c5e8f7;
}
a.bg-info:hover {
  background-color: #98d6f1;
}
.bg-warning {
  background-color: #ffefa4;
}
a.bg-warning:hover {
  background-color: #ffe671;
}
.bg-danger {
  background-color: #ffc9c9;
}
a.bg-danger:hover {
  background-color: #ff9696;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: #373e4a;
}
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #999999;
}
h1,
h2,
h3 {
  margin-top: 17px;
  margin-bottom: 8.5px;
}
h1 small,
h2 small,
h3 small,
h1 .small,
h2 .small,
h3 .small {
  font-size: 65%;
}
h4,
h5,
h6 {
  margin-top: 8.5px;
  margin-bottom: 8.5px;
}
h4 small,
h5 small,
h6 small,
h4 .small,
h5 .small,
h6 .small {
  font-size: 75%;
}
h1,
.h1 {
  font-size: 31px;
}
h2,
.h2 {
  font-size: 25px;
}
h3,
.h3 {
  font-size: 21px;
}
h4,
.h4 {
  font-size: 15px;
}
h5,
.h5 {
  font-size: 12px;
}
h6,
.h6 {
  font-size: 11px;
}
.bg-primary {
  color: #fff;
  background-color: #949494;
}
a.bg-primary:hover {
  background-color: #7b7b7b;
}
.bg-warning {
  background-color: #ffefa4;
}
a.bg-warning:hover {
  background-color: #ffe671;
}
.bg-danger {
  background-color: #ffc9c9;
}
a.bg-danger:hover {
  background-color: #ff9696;
}
.bg-success {
  background-color: #bdedbc;
}
a.bg-success:hover {
  background-color: #95e294;
}
.bg-info {
  background-color: #c5e8f7;
}
a.bg-info:hover {
  background-color: #98d6f1;
}
.page-header {
  padding-bottom: 7.5px;
  margin: 34px 0 17px;
  border-bottom: 1px solid #eeeeee;
}
ul,
ol {
  margin-top: 0;
  margin-bottom: 8.5px;
}
ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
.list-inline > li:first-child {
  padding-left: 0;
}
dl {
  margin-bottom: 17px;
}
dt,
dd {
  line-height: 1.42857143;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 0;
}
@media (min-width: 768px) {
  .dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .dl-horizontal dd {
    margin-left: 180px;
  }
  .dl-horizontal dd:before,
  .dl-horizontal dd:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
  }
  .dl-horizontal dd:after {
    clear: both;
  }
}
abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #999999;
}
abbr.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
blockquote {
  padding: 8.5px 17px;
  margin: 0 0 17px;
  border-left: 5px solid #eeeeee;
}
blockquote p {
  font-size: 15px;
  font-weight: 300;
  line-height: 1.25;
}
blockquote p:last-child {
  margin-bottom: 0;
}
blockquote small {
  display: block;
  line-height: 1.42857143;
  color: #999999;
}
blockquote small:before {
  content: '\2014 \00A0';
}
blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #eeeeee;
  border-left: 0;
}
blockquote.pull-right p,
blockquote.pull-right small,
blockquote.pull-right .small {
  text-align: right;
}
blockquote.pull-right small:before,
blockquote.pull-right .small:before {
  content: '';
}
blockquote.pull-right small:after,
blockquote.pull-right .small:after {
  content: '\00A0 \2014';
}
blockquote:before,
blockquote:after {
  content: "";
}
address {
  margin-bottom: 17px;
  font-style: normal;
  line-height: 1.42857143;
}
table {
  max-width: 100%;
  background-color: transparent;
}
th {
  text-align: left;
  font-weight: 400;
  color: #303641;
}
.table {
  width: 100%;
  margin-bottom: 17px;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ebebeb;
}
.table > thead > tr > th .progress,
.table > tbody > tr > th .progress,
.table > tfoot > tr > th .progress,
.table > thead > tr > td .progress,
.table > tbody > tr > td .progress,
.table > tfoot > tr > td .progress {
  margin-bottom: 0;
}
.table > thead > tr > th .label,
.table > tbody > tr > th .label,
.table > tfoot > tr > th .label,
.table > thead > tr > td .label,
.table > tbody > tr > td .label,
.table > tfoot > tr > td .label {
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ebebeb;
}
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}
.table > tbody + tbody {
  border-top: 2px solid #ebebeb;
}
.table .table {
  background-color: #ffffff;
}
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 5px;
}
.table-bordered {
  border: 1px solid #ebebeb;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #ebebeb;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  background-color: #f5f5f6;
  border-bottom-width: 1px;
  color: #a6a7aa;
}
.table-bordered > tfoot > tr > th,
.table-bordered > tfoot > tr > td {
  background-color: #f5f5f6;
  border-top-width: 1px;
  color: #a6a7aa;
}
.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #f8f8f8;
}
.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
  background-color: #f2f2f4;
}
table col[class*="col-"] {
  float: none;
  display: table-column;
}
table td[class*="col-"],
table th[class*="col-"] {
  float: none;
  display: table-cell;
}
.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #f2f2f4;
}
.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #f2f2f4;
}
.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr.active:hover > th {
  background-color: #e5e5e8;
}
.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background-color: #bdedbc;
}
.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr.success:hover > th {
  background-color: #a9e8a8;
}
.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background-color: #c5e8f7;
}
.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr.info:hover > th {
  background-color: #afdff4;
}
.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
  background-color: #ffefa4;
}
.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #ffeb8a;
}
.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: #ffc9c9;
}
.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #ffafaf;
}
@media (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 12.75px;
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ebebeb;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive > .table {
    margin-bottom: 0;
  }
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}
table > tbody > tr.highlight > td,
table > tbody > tr.highlight > th {
  background-color: #f1f2f4 !important;
  color: #303641;
}
.table > thead > tr > .middle-align,
.table > tbody > tr > .middle-align,
.table > tfoot > tr > .middle-align {
  vertical-align: middle;
}
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 17px;
  font-size: 18px;
  line-height: inherit;
  color: #7d8086;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}
label {
  align-items: center;
  margin-bottom: 0;
}
input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
input[type="radio"],
input[type="checkbox"] {
  /* margin: 4px 0 0;
  margin-top: 1px \9; */
  /* IE8-9 */
  line-height: normal;
}
input[type="file"] {
  display: block;
  padding: 5px;
}
input[type="range"] {
  display: block;
  width: 100%;
}
select[multiple],
select[size] {
  height: auto;
}
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
output {
  display: block;
  padding-top: 7px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #555555;
}
.form-control {
  display: block;
  width: 100%;
  height: 30px;
  padding: 0 12px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -moz-transition: border-color ease-in-out .15s, -moz-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.form-control:focus {
  border-color: #c8cdd7;
  outline: 0;
  -moz-box-shadow:  0 2px 1px rgba(203, 208, 217, 0.08);
  -webkit-box-shadow:  0 2px 1px rgba(203, 208, 217, 0.08);
  box-shadow:  0 2px 1px rgba(203, 208, 217, 0.08);
}
.form-control::-webkit-input-placeholder {
  color: #aaaaaa;
}
.form-control:-moz-placeholder {
  color: #aaaaaa;
}
.form-control::-moz-placeholder {
  color: #aaaaaa;
}
.form-control:-ms-input-placeholder {
  color: #aaaaaa;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  cursor: not-allowed;
  background-color: #eeeeee;
}
textarea.form-control {
  height: auto;
  padding: 6px 12px;
}
input[type="date"] {
  line-height: 31px;
}
.form-group {
  margin-bottom: 15px;
}
.radio,
.checkbox {
  display: block;
  min-height: 17px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
}
.radio label,
.checkbox label {
  display: inline;
  font-weight: normal;
  cursor: pointer;
}
.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  float: left;
  margin-left: -20px;
  margin-top: 1px;
}
.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px;
}
.radio-inline,
.checkbox-inline {
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
}
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}
input[type="radio"][disabled],
input[type="checkbox"][disabled],
.radio[disabled],
.radio-inline[disabled],
.checkbox[disabled],
.checkbox-inline[disabled],
fieldset[disabled] input[type="radio"],
fieldset[disabled] input[type="checkbox"],
fieldset[disabled] .radio,
fieldset[disabled] .radio-inline,
fieldset[disabled] .checkbox,
fieldset[disabled] .checkbox-inline {
  cursor: not-allowed;
}
.input-sm {
  height: 28px;
  padding: 5px 10px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}
select.input-sm {
  height: 28px;
  line-height: 28px;
}
textarea.input-sm,
select[multiple].input-sm {
  height: auto;
}
.input-lg {
  height: 41px;
  padding: 10px 16px;
  font-size: 15px;
  line-height: 1.33;
  border-radius: 3px;
}
select.input-lg {
  height: 41px;
  line-height: 41px;
}
textarea.input-lg,
select[multiple].input-lg {
  height: auto;
}
.has-feedback {
  position: relative;
}
.has-feedback .form-control {
  padding-right: 38.75px;
}
.has-feedback .form-control-feedback {
  position: absolute;
  top: 22px;
  right: 0;
  display: block;
  width: 31px;
  height: 31px;
  line-height: 31px;
  text-align: center;
}
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline {
  color: #ffd40b;
}
.has-warning .form-control {
  border-color: #ffd78a;
}
.has-warning .form-control:focus {
  border-color: #ffc658;
}
.has-warning .input-group-addon {
  color: #ffd40b;
  border-color: #ffd78a;
  background-color: #ffefa4;
}
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
  color: #ff3030;
}
.has-error .form-control {
  border-color: #ffafbd;
}
.has-error .form-control:focus {
  border-color: #ff7c92;
}
.has-error .input-group-addon {
  color: #ff3030;
  border-color: #ffafbd;
  background-color: #ffc9c9;
}
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline {
  color: #46cd43;
}
.has-success .form-control {
  border-color: #b4e8a8;
}
.has-success .form-control:focus {
  border-color: #91dd80;
}
.has-success .input-group-addon {
  color: #46cd43;
  border-color: #b4e8a8;
  background-color: #bdedbc;
}
.form-control-static {
  margin-bottom: 0;
}
.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #d4d4d4;
}
@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .input-group > .form-control {
    width: 100%;
  }
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .radio,
  .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    vertical-align: middle;
  }
  .form-inline .radio input[type="radio"],
  .form-inline .checkbox input[type="checkbox"] {
    float: none;
    margin-left: 0;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}
.form-horizontal .control-label,
.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px;
}
.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 24px;
}
.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px;
}
.form-horizontal .form-group:before,
.form-horizontal .form-group:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.form-horizontal .form-group:after {
  clear: both;
}
.form-horizontal .form-control-static {
  padding-top: 7px;
}
@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
  }
}
.form-horizontal .has-feedback .form-control-feedback {
  top: 0;
  right: 15px;
}
.bs-example > .btn,
.bs-example > .make-switch,
.bs-example > .btn-group {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 9px;
}
.bs-example.bs-baseline-top .btn,
.bs-example.bs-baseline-top .btn-group {
  vertical-align: top !important;
}
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.btn:hover{
  color: #303641;
  text-decoration: none;
  outline: none;
}
.btn:active,
.btn.active {
  outline: none;
  background-image: none;
  -moz-box-shadow: inset 0 0px 7px rgba(0, 0, 0, 0.225);
  -webkit-box-shadow: inset 0 0px 7px rgba(0, 0, 0, 0.225);
  box-shadow: inset 0 0px 7px rgba(0, 0, 0, 0.225);
  -moz-box-shadow: inset 0 0px 4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0px 4px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0px 4px rgba(0, 0, 0, 0.2);
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  pointer-events: none;
  -webkit-opacity: 0.65;
  -moz-opacity: 0.65;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-icon {
  position: relative;
}
.btn.btn-icon i {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}
.btn-default {
  color: #303641;
  background-color: #f0f0f1;
  border-color: #f0f0f1;
}
.btn-default:hover,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  color: #303641;
  background-color: #dbdbdd;
  border-color: #d0d0d3;
}
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: #f0f0f1;
  border-color: #f0f0f1;
}
.btn-default .badge {
  color: #f0f0f1;
  background-color: #303641;
}
.btn-default > .caret {
  border-top-color: #303641;
  border-bottom-color: #303641 !important;
}
.btn-default.dropdown-toggle {
  border-left-color: #dedee0;
}
.btn-default.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}
.btn-default.btn-icon i {
  background-color: #dbdbdd;
  padding: 6px 6px;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 3px;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}
.btn-default.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}
.btn-default.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}
.btn-default.btn-icon.btn-lg {
  padding-right: 55px;
}
.btn-default.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}
.btn-default.btn-icon.btn-lg i {
  padding: 10px 10px;
  font-size: 15px;
  line-height: 1.33;
  border-radius: 3px;
}
.btn-default.btn-icon.btn-sm {
  padding-right: 36px;
}
.btn-default.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}
.btn-default.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-default.btn-icon.btn-xs {
  padding-right: 32px;
}
.btn-default.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}
.btn-default.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}

/* babi */
.btn-babi {
  color: #ffffff;
  background-color: #897DFF;
  border-color: #897DFF;
}
.btn-babi:hover,
.btn-babi:focus,
.btn-babi:active,
.btn-babi.active,
.open .dropdown-toggle.btn-babi {
  color: #ffffff !important;
  background-color: #5f57b2;
  border-color: #5f57b2;
}
/* babi */

.btn-primary {
  color: #ffffff;
  background-color: #303641;
  border-color: #303641;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: #ffffff;
  background-color: #1f232a;
  border-color: #16191e;
}
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #303641;
  border-color: #303641;
}
.btn-primary .badge {
  color: #303641;
  background-color: #ffffff;
}
.btn-primary > .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff !important;
}
.btn-primary.dropdown-toggle {
  border-left-color: #21252c;
}
.btn-primary.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}
.btn-primary.btn-icon i {
  background-color: #1f232a;
  padding: 6px 6px;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 3px;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}
.btn-primary.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}
.btn-primary.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}
.btn-primary.btn-icon.btn-lg {
  padding-right: 55px;
}
.btn-primary.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}
.btn-primary.btn-icon.btn-lg i {
  padding: 10px 10px;
  font-size: 15px;
  line-height: 1.33;
  border-radius: 3px;
}
.btn-primary.btn-icon.btn-sm {
  padding-right: 36px;
}
.btn-primary.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}
.btn-primary.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-primary.btn-icon.btn-xs {
  padding-right: 32px;
}
.btn-primary.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}
.btn-primary.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-blue {
  color: #ffffff;
  background-color: #0072bc;
  border-color: #0072bc;
}
.btn-blue:hover,
.btn-blue:focus,
.btn-blue:active,
.btn-blue.active,
.open .dropdown-toggle.btn-blue {
  color: #ffffff;
  background-color: #005993;
  border-color: #004d7f;
}
.btn-blue:active,
.btn-blue.active,
.open .dropdown-toggle.btn-blue {
  background-image: none;
}
.btn-blue.disabled,
.btn-blue[disabled],
fieldset[disabled] .btn-blue,
.btn-blue.disabled:hover,
.btn-blue[disabled]:hover,
fieldset[disabled] .btn-blue:hover,
.btn-blue.disabled:focus,
.btn-blue[disabled]:focus,
fieldset[disabled] .btn-blue:focus,
.btn-blue.disabled:active,
.btn-blue[disabled]:active,
fieldset[disabled] .btn-blue:active,
.btn-blue.disabled.active,
.btn-blue[disabled].active,
fieldset[disabled] .btn-blue.active {
  background-color: #0072bc;
  border-color: #0072bc;
}
.btn-blue .badge {
  color: #0072bc;
  background-color: #ffffff;
}
.btn-blue > .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff !important;
}
.btn-blue.dropdown-toggle {
  border-left-color: #005c98;
}
.btn-blue.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}
.btn-blue.btn-icon i {
  background-color: #005993;
  padding: 6px 6px;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 3px;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}
.btn-blue.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}
.btn-blue.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}
.btn-blue.btn-icon.btn-lg {
  padding-right: 55px;
}
.btn-blue.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}
.btn-blue.btn-icon.btn-lg i {
  padding: 10px 10px;
  font-size: 15px;
  line-height: 1.33;
  border-radius: 3px;
}
.btn-blue.btn-icon.btn-sm {
  padding-right: 36px;
}
.btn-blue.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}
.btn-blue.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-blue.btn-icon.btn-xs {
  padding-right: 32px;
}
.btn-blue.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}
.btn-blue.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-red {
  color: #ffffff;
  background-color: #d42020;
  border-color: #d42020;
}
.btn-red:hover,
.btn-red:focus,
.btn-red:active,
.btn-red.active,
.open .dropdown-toggle.btn-red {
  color: #ffffff;
  background-color: #b11b1b;
  border-color: #9f1818;
}
.btn-red:active,
.btn-red.active,
.open .dropdown-toggle.btn-red {
  background-image: none;
}
.btn-red.disabled,
.btn-red[disabled],
fieldset[disabled] .btn-red,
.btn-red.disabled:hover,
.btn-red[disabled]:hover,
fieldset[disabled] .btn-red:hover,
.btn-red.disabled:focus,
.btn-red[disabled]:focus,
fieldset[disabled] .btn-red:focus,
.btn-red.disabled:active,
.btn-red[disabled]:active,
fieldset[disabled] .btn-red:active,
.btn-red.disabled.active,
.btn-red[disabled].active,
fieldset[disabled] .btn-red.active {
  background-color: #d42020;
  border-color: #d42020;
}
.btn-red .badge {
  color: #d42020;
  background-color: #ffffff;
}
.btn-red > .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff !important;
}
.btn-red.dropdown-toggle {
  border-left-color: #b51b1b;
}
.btn-red.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}
.btn-red.btn-icon i {
  background-color: #b11b1b;
  padding: 6px 6px;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 3px;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}
.btn-red.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}
.btn-red.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}
.btn-red.btn-icon.btn-lg {
  padding-right: 55px;
}
.btn-red.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}
.btn-red.btn-icon.btn-lg i {
  padding: 10px 10px;
  font-size: 15px;
  line-height: 1.33;
  border-radius: 3px;
}
.btn-red.btn-icon.btn-sm {
  padding-right: 36px;
}
.btn-red.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}
.btn-red.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-red.btn-icon.btn-xs {
  padding-right: 32px;
}
.btn-red.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}
.btn-red.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-orange {
  color: #ffffff;
  background-color: #ff9600;
  border-color: #ff9600;
}
.btn-orange:hover,
.btn-orange:active,
.btn-orange.active,
.open .dropdown-toggle.btn-orange {
  color: #ffffff;
  background-color: #d67e00;
  border-color: #c27200;
}
.btn-orange:active,
.btn-orange.active,
.open .dropdown-toggle.btn-orange {
  background-image: none;
}
.btn-orange.disabled,
.btn-orange[disabled],
fieldset[disabled] .btn-orange,
.btn-orange.disabled:hover,
.btn-orange[disabled]:hover,
fieldset[disabled] .btn-orange:hover,
.btn-orange.disabled:focus,
.btn-orange[disabled]:focus,
fieldset[disabled] .btn-orange:focus,
.btn-orange.disabled:active,
.btn-orange[disabled]:active,
fieldset[disabled] .btn-orange:active,
.btn-orange.disabled.active,
.btn-orange[disabled].active,
fieldset[disabled] .btn-orange.active {
  background-color: #ff9600;
  border-color: #ff9600;
}
.btn-orange .badge {
  color: #ff9600;
  background-color: #ffffff;
}
.btn-orange > .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff !important;
}
.btn-orange.dropdown-toggle {
  border-left-color: #db8100;
}
.btn-orange.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}
.btn-orange.btn-icon i {
  background-color: #d67e00;
  padding: 6px 6px;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 3px;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}
.btn-orange.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}
.btn-orange.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}
.btn-orange.btn-icon.btn-lg {
  padding-right: 55px;
}
.btn-orange.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}
.btn-orange.btn-icon.btn-lg i {
  padding: 10px 10px;
  font-size: 15px;
  line-height: 1.33;
  border-radius: 3px;
}
.btn-orange.btn-icon.btn-sm {
  padding-right: 36px;
}
.btn-orange.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}
.btn-orange.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-orange.btn-icon.btn-xs {
  padding-right: 32px;
}
.btn-orange.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}
.btn-orange.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-gold {
  color: #846e20;
  background-color: #fcd036;
  border-color: #fcd036;
}
.btn-gold:hover,
.btn-gold:focus,
.btn-gold:active,
.btn-gold.active,
.open .dropdown-toggle.btn-gold {
  color: #846e20;
  background-color: #fbc70e;
  border-color: #f1bc04;
}
.btn-gold:active,
.btn-gold.active,
.open .dropdown-toggle.btn-gold {
  background-image: none;
}
.btn-gold.disabled,
.btn-gold[disabled],
fieldset[disabled] .btn-gold,
.btn-gold.disabled:hover,
.btn-gold[disabled]:hover,
fieldset[disabled] .btn-gold:hover,
.btn-gold.disabled:focus,
.btn-gold[disabled]:focus,
fieldset[disabled] .btn-gold:focus,
.btn-gold.disabled:active,
.btn-gold[disabled]:active,
fieldset[disabled] .btn-gold:active,
.btn-gold.disabled.active,
.btn-gold[disabled].active,
fieldset[disabled] .btn-gold.active {
  background-color: #fcd036;
  border-color: #fcd036;
}
.btn-gold .badge {
  color: #fcd036;
  background-color: #846e20;
}
.btn-gold > .caret {
  border-top-color: #846e20;
  border-bottom-color: #846e20 !important;
}
.btn-gold.dropdown-toggle {
  border-left-color: #fbc813;
}
.btn-gold.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}
.btn-gold.btn-icon i {
  background-color: #fbc70e;
  padding: 6px 6px;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 3px;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}
.btn-gold.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}
.btn-gold.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}
.btn-gold.btn-icon.btn-lg {
  padding-right: 55px;
}
.btn-gold.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}
.btn-gold.btn-icon.btn-lg i {
  padding: 10px 10px;
  font-size: 15px;
  line-height: 1.33;
  border-radius: 3px;
}
.btn-gold.btn-icon.btn-sm {
  padding-right: 36px;
}
.btn-gold.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}
.btn-gold.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-gold.btn-icon.btn-xs {
  padding-right: 32px;
}
.btn-gold.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}
.btn-gold.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-black {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-black:hover,
.btn-black:focus,
.btn-black:active,
.btn-black.active,
.open .dropdown-toggle.btn-black {
  color: #ffffff;
  background-color: #000000;
  border-color: #000000;
}
.btn-black:active,
.btn-black.active,
.open .dropdown-toggle.btn-black {
  background-image: none;
}
.btn-black.disabled,
.btn-black[disabled],
fieldset[disabled] .btn-black,
.btn-black.disabled:hover,
.btn-black[disabled]:hover,
fieldset[disabled] .btn-black:hover,
.btn-black.disabled:focus,
.btn-black[disabled]:focus,
fieldset[disabled] .btn-black:focus,
.btn-black.disabled:active,
.btn-black[disabled]:active,
fieldset[disabled] .btn-black:active,
.btn-black.disabled.active,
.btn-black[disabled].active,
fieldset[disabled] .btn-black.active {
  background-color: #000000;
  border-color: #000000;
}
.btn-black .badge {
  color: #000000;
  background-color: #ffffff;
}
.btn-black > .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff !important;
}
.btn-black.dropdown-toggle {
  border-left-color: #000000;
}
.btn-black.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}
.btn-black.btn-icon i {
  background-color: #000000;
  padding: 6px 6px;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 3px;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}
.btn-black.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}
.btn-black.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}
.btn-black.btn-icon.btn-lg {
  padding-right: 55px;
}
.btn-black.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}
.btn-black.btn-icon.btn-lg i {
  padding: 10px 10px;
  font-size: 15px;
  line-height: 1.33;
  border-radius: 3px;
}
.btn-black.btn-icon.btn-sm {
  padding-right: 36px;
}
.btn-black.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}
.btn-black.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-black.btn-icon.btn-xs {
  padding-right: 32px;
}
.btn-black.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}
.btn-black.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-white {
  color: #303641;
  background-color: #ffffff;
  border-color: #ffffff;
  border-color: #ebebeb;
}
.btn-white:hover,
.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white {
  color: #303641;
  background-color: #ebebeb;
  border-color: #e0e0e0;
}
.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white {
  background-image: none;
}
.btn-white.disabled,
.btn-white[disabled],
fieldset[disabled] .btn-white,
.btn-white.disabled:hover,
.btn-white[disabled]:hover,
fieldset[disabled] .btn-white:hover,
.btn-white.disabled:focus,
.btn-white[disabled]:focus,
fieldset[disabled] .btn-white:focus,
.btn-white.disabled:active,
.btn-white[disabled]:active,
fieldset[disabled] .btn-white:active,
.btn-white.disabled.active,
.btn-white[disabled].active,
fieldset[disabled] .btn-white.active {
  background-color: #ffffff;
  border-color: #ffffff;
}
.btn-white .badge {
  color: #ffffff;
  background-color: #303641;
}
.btn-white > .caret {
  border-top-color: #303641;
  border-bottom-color: #303641 !important;
}
.btn-white.dropdown-toggle {
  border-left-color: #ededed;
}
.btn-white.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}
.btn-white.btn-icon i {
  background-color: #ebebeb;
  padding: 6px 6px;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 3px;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}
.btn-white.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}
.btn-white.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}
.btn-white.btn-icon.btn-lg {
  padding-right: 55px;
}
.btn-white.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}
.btn-white.btn-icon.btn-lg i {
  padding: 10px 10px;
  font-size: 15px;
  line-height: 1.33;
  border-radius: 3px;
}
.btn-white.btn-icon.btn-sm {
  padding-right: 36px;
}
.btn-white.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}
.btn-white.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-white.btn-icon.btn-xs {
  padding-right: 32px;
}
.btn-white.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}
.btn-white.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-warning {
  color: #ffffff;
  background-color: #fad839;
  border-color: #fad839;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  color: #ffffff;
  background-color: #f9d011;
  border-color: #f0c706;
}
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  background-image: none;
}
.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
  background-color: #fad839;
  border-color: #fad839;
}
.btn-warning .badge {
  color: #fad839;
  background-color: #ffffff;
}
.btn-warning > .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff !important;
}
.btn-warning.dropdown-toggle {
  border-left-color: #f9d116;
}
.btn-warning.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}
.btn-warning.btn-icon i {
  background-color: #f9d011;
  padding: 6px 6px;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 3px;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}
.btn-warning.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}
.btn-warning.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}
.btn-warning.btn-icon.btn-lg {
  padding-right: 55px;
}
.btn-warning.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}
.btn-warning.btn-icon.btn-lg i {
  padding: 10px 10px;
  font-size: 15px;
  line-height: 1.33;
  border-radius: 3px;
}
.btn-warning.btn-icon.btn-sm {
  padding-right: 36px;
}
.btn-warning.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}
.btn-warning.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-warning.btn-icon.btn-xs {
  padding-right: 32px;
}
.btn-warning.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}
.btn-warning.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-danger {
  color: #ffffff;
  background-color: #cc2424;
  border-color: #cc2424;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  color: #ffffff;
  background-color: #a91e1e;
  border-color: #981b1b;
}
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  background-image: none;
}
.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
  background-color: #cc2424;
  border-color: #cc2424;
}
.btn-danger .badge {
  color: #cc2424;
  background-color: #ffffff;
}
.btn-danger > .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff !important;
}
.btn-danger.dropdown-toggle {
  border-left-color: #ae1f1f;
}
.btn-danger.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}
.btn-danger.btn-icon i {
  background-color: #a91e1e;
  padding: 6px 6px;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 3px;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}
.btn-danger.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}
.btn-danger.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}
.btn-danger.btn-icon.btn-lg {
  padding-right: 55px;
}
.btn-danger.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}
.btn-danger.btn-icon.btn-lg i {
  padding: 10px 10px;
  font-size: 15px;
  line-height: 1.33;
  border-radius: 3px;
}
.btn-danger.btn-icon.btn-sm {
  padding-right: 36px;
}
.btn-danger.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}
.btn-danger.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-danger.btn-icon.btn-xs {
  padding-right: 32px;
}
.btn-danger.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}
.btn-danger.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-success,
.btn-green {
  color: #ffffff;
  background-color: #00a651;
  border-color: #00a651;
}
.btn-success:hover,
.btn-green:hover,
.btn-success:focus,
.btn-green:focus,
.btn-success:active,
.btn-green:active,
.btn-success.active,
.btn-green.active,
.open .dropdown-toggle.btn-success,
.open .dropdown-toggle.btn-green {
  color: #ffffff;
  background-color: #007d3d;
  border-color: #006933;
}
.btn-success:active,
.btn-green:active,
.btn-success.active,
.btn-green.active,
.open .dropdown-toggle.btn-success,
.open .dropdown-toggle.btn-green {
  background-image: none;
}
.btn-success.disabled,
.btn-green.disabled,
.btn-success[disabled],
.btn-green[disabled],
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-green,
.btn-success.disabled:hover,
.btn-green.disabled:hover,
.btn-success[disabled]:hover,
.btn-green[disabled]:hover,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-green:hover,
.btn-success.disabled:focus,
.btn-green.disabled:focus,
.btn-success[disabled]:focus,
.btn-green[disabled]:focus,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-green:focus,
.btn-success.disabled:active,
.btn-green.disabled:active,
.btn-success[disabled]:active,
.btn-green[disabled]:active,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-green:active,
.btn-success.disabled.active,
.btn-green.disabled.active,
.btn-success[disabled].active,
.btn-green[disabled].active,
fieldset[disabled] .btn-success.active,
fieldset[disabled] .btn-green.active {
  background-color: #00a651;
  border-color: #00a651;
}
.btn-success .badge,
.btn-green .badge {
  color: #00a651;
  background-color: #ffffff;
}
.btn-success > .caret,
.btn-green > .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff !important;
}
.btn-success.dropdown-toggle,
.btn-green.dropdown-toggle {
  border-left-color: #008240;
}
.btn-success.btn-icon,
.btn-green.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}
.btn-success.btn-icon i,
.btn-green.btn-icon i {
  background-color: #007d3d;
  padding: 6px 6px;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 3px;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}
.btn-success.btn-icon.icon-left,
.btn-green.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}
.btn-success.btn-icon.icon-left i,
.btn-green.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}
.btn-success.btn-icon.btn-lg,
.btn-green.btn-icon.btn-lg {
  padding-right: 55px;
}
.btn-success.btn-icon.btn-lg.icon-left,
.btn-green.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}
.btn-success.btn-icon.btn-lg i,
.btn-green.btn-icon.btn-lg i {
  padding: 10px 10px;
  font-size: 15px;
  line-height: 1.33;
  border-radius: 3px;
}
.btn-success.btn-icon.btn-sm,
.btn-green.btn-icon.btn-sm {
  padding-right: 36px;
}
.btn-success.btn-icon.btn-sm.icon-left,
.btn-green.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}
.btn-success.btn-icon.btn-sm i,
.btn-green.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-success.btn-icon.btn-xs,
.btn-green.btn-icon.btn-xs {
  padding-right: 32px;
}
.btn-success.btn-icon.btn-xs.icon-left,
.btn-green.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}
.btn-success.btn-icon.btn-xs i,
.btn-green.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-info {
  color: #ffffff;
  background-color: #21a9e1;
  border-color: #21a9e1;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  color: #ffffff;
  background-color: #1a8fbf;
  border-color: #1782ad;
}
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-image: none;
}
.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  background-color: #21a9e1;
  border-color: #21a9e1;
}
.btn-info .badge {
  color: #21a9e1;
  background-color: #ffffff;
}
.btn-info > .caret {
  border-top-color: #ffffff;
  border-bottom-color: #ffffff !important;
}
.btn-info.dropdown-toggle {
  border-left-color: #1a92c4;
}
.btn-info.btn-icon {
  position: relative;
  padding-right: 39px;
  border: none;
}
.btn-info.btn-icon i {
  background-color: #1a8fbf;
  padding: 6px 6px;
  font-size: 12px;
  line-height: 1.42857143;
  border-radius: 3px;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}
.btn-info.btn-icon.icon-left {
  padding-right: 12px;
  padding-left: 39px;
}
.btn-info.btn-icon.icon-left i {
  float: left;
  right: auto;
  left: 0;
  -webkit-border-radius: 3px 0 0 3px !important;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px !important;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px !important;
  background-clip: padding-box;
}
.btn-info.btn-icon.btn-lg {
  padding-right: 55px;
}
.btn-info.btn-icon.btn-lg.icon-left {
  padding-right: 16px;
  padding-left: 55px;
}
.btn-info.btn-icon.btn-lg i {
  padding: 10px 10px;
  font-size: 15px;
  line-height: 1.33;
  border-radius: 3px;
}
.btn-info.btn-icon.btn-sm {
  padding-right: 36px;
}
.btn-info.btn-icon.btn-sm.icon-left {
  padding-right: 10px;
  padding-left: 36px;
}
.btn-info.btn-icon.btn-sm i {
  padding: 5px 6px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-info.btn-icon.btn-xs {
  padding-right: 32px;
}
.btn-info.btn-icon.btn-xs.icon-left {
  padding-right: 10px;
  padding-left: 32px;
}
.btn-info.btn-icon.btn-xs i {
  padding: 2px 6px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-link {
  color: #373e4a;
  font-weight: normal;
  cursor: pointer;
  border-radius: 0;
}
.btn-link,
.btn-link:active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  border-color: transparent;
}
.btn-link:hover,
.btn-link:focus {
  color: #818da2;
  text-decoration: underline;
  background-color: transparent;
}
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus {
  color: #999999;
  text-decoration: none;
}
.btn-lg {
  padding: 10px 16px;
  font-size: 15px;
  line-height: 1.33;
  border-radius: 3px;
}
.btn-sm,
.btn-xs {
  padding: 5px 10px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}
.btn-xs {
  padding: 1px 5px;
}
.btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.btn-block + .btn-block {
  margin-top: 5px;
}
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}
.morris-hover {
  position: absolute;
  z-index: 1000;
}
.morris-hover.morris-default-style {
  border-radius: 10px;
  padding: 6px;
  color: #666;
  background: rgba(255, 255, 255, 0.8);
  border: solid 2px rgba(230, 230, 230, 0.8);
  font-family: sans-serif;
  font-size: 12px;
  text-align: center;
}
.morris-hover.morris-default-style .morris-hover-row-label {
  font-weight: bold;
  margin: 0.25em 0;
}
.morris-hover.morris-default-style .morris-hover-point {
  white-space: nowrap;
  margin: 0.1em 0;
}
.page-container {
  position: absolute;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding-left: 280px;
  width: 100%;
}
.page-container:before,
.page-container:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.page-container:after {
  clear: both;
}
.page-container.loaded .page-container {
  position: relative;
}
.page-container .sidebar-menu {
  position: absolute;
  width: 280px;
  top: 0;
  left: 0;
  bottom: 0;
  background: #303641;
  color: #aaabae;
  z-index: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.page-container .sidebar-menu.fixed {
  position: fixed;
  min-height: 0 !important;
  height: auto;
  bottom: 0;
}
.page-container .sidebar-menu .logo-env {
  width: 100%;
  /* padding: 40px; */
  padding: 40px 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  overflow-x: auto;
}
.page-container .sidebar-menu .logo-env:before,
.page-container .sidebar-menu .logo-env:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.page-container .sidebar-menu .logo-env:after {
  clear: both;
}
.page-container .sidebar-menu .logo-env > div {
  /* display: block; */
  vertical-align: middle;
  white-space: nowrap;
  /* float: left; */
}
.page-container .sidebar-menu .logo-env > div > a {
  display: inline-block;
  color: #aaabae;
}
.page-container .sidebar-menu .logo-env > div.sidebar-collapse,
.page-container .sidebar-menu .logo-env > div.sidebar-mobile-menu {
  position: relative;
  float: right;
}
.page-container .sidebar-menu .logo-env > div.sidebar-collapse a,
.page-container .sidebar-menu .logo-env > div.sidebar-mobile-menu a {
  display: inline-block;
  border: 1px solid #454a54;
  text-align: center;
  padding: 0;
  line-height: 1;
  font-size: 20px;
  font-weight: 300;
  padding: 5px 2px;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
.page-container .sidebar-menu .logo-env > div.sidebar-collapse a:hover,
.page-container .sidebar-menu .logo-env > div.sidebar-mobile-menu a:hover {
  background-color: rgba(69, 74, 84, 0.4);
}
.page-container .sidebar-menu #main-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}
.page-container .sidebar-menu #main-menu li {
  position: relative;
  margin: 0;
  font-size: 12px;
  border-bottom: 1px solid rgba(69, 74, 84, 0.7);
}
.page-container .sidebar-menu #main-menu li#search {
  background: #262b34;
  border-bottom-color: rgba(69, 74, 84, 0.5);
  position: relative;
  height: 48px;
}
.page-container .sidebar-menu #main-menu li#search ::-webkit-input-placeholder {
  color: rgba(170, 171, 174, 0.7);
}
.page-container .sidebar-menu #main-menu li#search :-moz-placeholder {
  color: rgba(170, 171, 174, 0.7);
}
.page-container .sidebar-menu #main-menu li#search ::-moz-placeholder {
  color: rgba(170, 171, 174, 0.7);
}
.page-container .sidebar-menu #main-menu li#search :-ms-input-placeholder {
  color: rgba(170, 171, 174, 0.7);
}
.page-container .sidebar-menu #main-menu li#search .search-input {
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  padding: 15px 20px;
  padding-right: 45px;
  width: 100%;
  color: #aaabae;
  -moz-transition: 350ms opacity ease-in-out;
  -o-transition: 350ms opacity ease-in-out;
  -webkit-transition: 350ms opacity ease-in-out;
  transition: 350ms opacity ease-in-out;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.page-container .sidebar-menu #main-menu li#search button {
  position: absolute;
  right: 0;
  top: 0;
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  color: #aaabae;
  padding: 15px 20px;
  padding-top: 13px;
}
.page-container .sidebar-menu #main-menu li#search button i {
  margin: 0;
}
.page-container .sidebar-menu #main-menu li i {
  position: relative;
  font-size: 15px;
  margin-right: 5px;
}
.page-container .sidebar-menu #main-menu li a {
  position: relative;
  display: block;
  padding: 10px 20px;
  color: #aaabae;
  z-index: 2;
  -moz-transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
  -o-transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
  -webkit-transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
  transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
}
.page-container .sidebar-menu #main-menu li a i {
  top: 1px;
  display: inline-block;
}
.page-container .sidebar-menu #main-menu li a span {
  -moz-transition: 350ms opacity ease-in-out;
  -o-transition: 350ms opacity ease-in-out;
  -webkit-transition: 350ms opacity ease-in-out;
  transition: 350ms opacity ease-in-out;
}
.page-container .sidebar-menu #main-menu li a:hover {
  background-color: rgba(69, 74, 84, 0.3);
  color: #ffffff;
}
.page-container .sidebar-menu #main-menu li a .badge {
  position: relative;
  float: right;
  font-size: 11px;
  line-height: 1.3;
}
.page-container .sidebar-menu #main-menu li a .badge.badge-primary {
  border: 1px solid #454a54;
  top: -1px;
}
.page-container .sidebar-menu #main-menu li.active > a {
  background-color: #2b303a;
  color: #ffffff;
}
.page-container .sidebar-menu #main-menu li.has-sub > a:before {
  position: relative;
  content: '\e877';
  content: '\e879';
  display: inline-block;
  font-family: 'Entypo';
  color: #454a54;
  color: #FFF;
  float: right;
  font-size: 15px;
  margin-left: 13px;
  top: 0px;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.page-container .sidebar-menu #main-menu li.has-sub.opened > a:before {
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.page-container .sidebar-menu #main-menu li ul {
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: 1px solid rgba(69, 74, 84, 0.4);
  display: none;
  overflow: hidden;
  z-index: 1;
}
.page-container .sidebar-menu #main-menu li ul.visible {
  display: block;
}
.page-container .sidebar-menu #main-menu li ul > li {
  border-bottom: 1px solid rgba(69, 74, 84, 0.4);
}
.page-container .sidebar-menu #main-menu li ul > li > a {
  background-color: #2b303a;
  padding-left: 40px;
}
.page-container .sidebar-menu #main-menu li ul > li > a:hover {
  background-color: #2d323d;
}
.page-container .sidebar-menu #main-menu li ul > li:last-child {
  border-bottom: 0;
}
.page-container .sidebar-menu #main-menu li ul > li ul > li > a {
  padding-left: 60px;
  background: #262b34;
}
.page-container .sidebar-menu #main-menu li ul > li ul > li ul > li > a {
  padding-left: 80px;
  background: #262b34;
}
.page-container .sidebar-menu #main-menu li ul > li ul > li ul > li ul > li > a {
  padding-left: 100px;
  background: #262b34;
}
.page-container .sidebar-menu .sidebar-user-info {
  position: relative;
  padding: 25px 35px;
  border-top: 1px solid #454a54;
  -moz-transition: padding 300ms ease-in-out;
  -o-transition: padding 300ms ease-in-out;
  -webkit-transition: padding 300ms ease-in-out;
  transition: padding 300ms ease-in-out;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.page-container .sidebar-menu .sidebar-user-info .user-link {
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.page-container .sidebar-menu .sidebar-user-info .user-link:before,
.page-container .sidebar-menu .sidebar-user-info .user-link:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.page-container .sidebar-menu .sidebar-user-info .user-link:after {
  clear: both;
}
.page-container .sidebar-menu .sidebar-user-info .user-link img {
  float: left;
  margin-right: 15px;
  border: 4px solid rgba(255, 255, 255, 0.11);
  -webkit-transition: -webkit-transform 350ms cubic-bezier(0.445, 0.050, 0.550, 0.950);
  -moz-transition: -moz-transform 350ms cubic-bezier(0.445, 0.050, 0.550, 0.950);
  -o-transition: -o-transform 350ms cubic-bezier(0.445, 0.050, 0.550, 0.950);
  transition: -webkit-transform 350ms cubic-bezier(0.445, 0.050, 0.550, 0.950),-moz-transform 350ms cubic-bezier(0.445, 0.050, 0.550, 0.950),-o-transform 350ms cubic-bezier(0.445, 0.050, 0.550, 0.950),transform 350ms cubic-bezier(0.445, 0.050, 0.550, 0.950);
  -webkit-transition: transform 350ms cubic-bezier(0.445, 0.050, 0.550, 0.950);
  transition: transform 350ms cubic-bezier(0.445, 0.050, 0.550, 0.950);
  display: block;
  max-width: 100%;
  height: auto;
}
.page-container .sidebar-menu .sidebar-user-info .user-link span,
.page-container .sidebar-menu .sidebar-user-info .user-link strong {
  display: block;
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  line-height: 1.2;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
  -moz-transition: opacity 300ms ease-in-out;
  -o-transition: opacity 300ms ease-in-out;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
}
.page-container .sidebar-menu .sidebar-user-info .user-link span {
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.page-container .sidebar-menu .sidebar-user-info .user-link strong {
  font-size: 18px;
}
.page-container .sidebar-menu .sidebar-user-info .user-link img + span,
.page-container .sidebar-menu .sidebar-user-info .user-link img + strong {
  padding-top: 8px;
}
.page-container .sidebar-menu .sidebar-user-info .sui-normal {
  position: relative;
}
.page-container .sidebar-menu .sidebar-user-info .sui-hover {
  position: absolute;
  visibility: hidden;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #2b303a;
  padding: 25px 27.5px;
  -moz-transition: opacity 200ms ease-in-out;
  -o-transition: opacity 200ms ease-in-out;
  -webkit-transition: opacity 200ms ease-in-out;
  transition: opacity 200ms ease-in-out;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
.page-container .sidebar-menu .sidebar-user-info .sui-hover .close-sui-popup {
  position: absolute;
  right: 15px;
  top: 10px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 11px;
  cursor: pointer;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.page-container .sidebar-menu .sidebar-user-info .sui-hover a {
  position: relative;
  color: #ffffff;
  display: block;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.page-container .sidebar-menu .sidebar-user-info .sui-hover a + a {
  margin-left: 0;
  margin-top: 5px;
}
.page-container .sidebar-menu .sidebar-user-info .sui-hover.animate-in a {
  top: -20px;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  -moz-transition: all 350ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
  -o-transition: all 350ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
  -webkit-transition: all 350ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
  transition: all 350ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
  -moz-transition-delay: 90ms;
  -o-transition-delay: 90ms;
  -webkit-transition-delay: 90ms;
  transition-delay: 90ms;
}
.page-container .sidebar-menu .sidebar-user-info .sui-hover.animate-in a + a {
  -moz-transition-delay: 180ms;
  -o-transition-delay: 180ms;
  -webkit-transition-delay: 180ms;
  transition-delay: 180ms;
}
.page-container .sidebar-menu .sidebar-user-info .sui-hover.animate-in a + a + a {
  -moz-transition-delay: 270ms;
  -o-transition-delay: 270ms;
  -webkit-transition-delay: 270ms;
  transition-delay: 270ms;
}
.page-container .sidebar-menu .sidebar-user-info .sui-hover.animate-in a + a + a + a {
  -moz-transition-delay: 360ms;
  -o-transition-delay: 360ms;
  -webkit-transition-delay: 360ms;
  transition-delay: 360ms;
}
.page-container .sidebar-menu .sidebar-user-info .sui-hover.animate-in a + a + a + a + a {
  -moz-transition-delay: 450ms;
  -o-transition-delay: 450ms;
  -webkit-transition-delay: 450ms;
  transition-delay: 450ms;
}
.page-container .sidebar-menu .sidebar-user-info .sui-hover.animate-in a + a + a + a + a + a {
  -moz-transition-delay: 540ms;
  -o-transition-delay: 540ms;
  -webkit-transition-delay: 540ms;
  transition-delay: 540ms;
}
.page-container .sidebar-menu .sidebar-user-info .sui-hover.animate-in a + a + a + a + a + a + a {
  -moz-transition-delay: 630ms;
  -o-transition-delay: 630ms;
  -webkit-transition-delay: 630ms;
  transition-delay: 630ms;
}
.page-container .sidebar-menu .sidebar-user-info .sui-hover.animate-in a + a + a + a + a + a + a + a {
  -moz-transition-delay: 720ms;
  -o-transition-delay: 720ms;
  -webkit-transition-delay: 720ms;
  transition-delay: 720ms;
}
.page-container .sidebar-menu .sidebar-user-info .sui-hover.animate-in a + a + a + a + a + a + a + a + a {
  -moz-transition-delay: 810ms;
  -o-transition-delay: 810ms;
  -webkit-transition-delay: 810ms;
  transition-delay: 810ms;
}
.page-container .sidebar-menu .sidebar-user-info .sui-hover.animate-in a + a + a + a + a + a + a + a + a + a {
  -moz-transition-delay: 900ms;
  -o-transition-delay: 900ms;
  -webkit-transition-delay: 900ms;
  transition-delay: 900ms;
}
.page-container .sidebar-menu .sidebar-user-info .sui-hover.animate-in a + a + a + a + a + a + a + a + a + a + a {
  -moz-transition-delay: 990ms;
  -o-transition-delay: 990ms;
  -webkit-transition-delay: 990ms;
  transition-delay: 990ms;
}
.page-container .sidebar-menu .sidebar-user-info .sui-hover.visible {
  visibility: visible;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}
.page-container .sidebar-menu .sidebar-user-info .sui-hover.visible .close-sui-popup {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}
.page-container .sidebar-menu .sidebar-user-info .sui-hover.visible.animate-in a {
  top: 0;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}
.page-container .sidebar-menu .sidebar-user-info .sui-hover.going-invisible {
  visibility: visible;
}
.page-container .sidebar-menu .sidebar-user-info .sui-hover.inline-links a {
  line-height: 52px;
  color: #ffffff;
  display: inline-block;
}
.page-container .sidebar-menu .sidebar-user-info .sui-hover.inline-links a + a {
  margin-left: 10px;
}
.page-container .main-content {
  position: relative;
  float: left;
  width: 100%;
  padding: 20px;
  z-index: 2;
  background: #ffffff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.page-container.sidebar-collapsed {
  padding-left: 65px;
}
.page-container.sidebar-collapsed .sidebar-user-info {
  padding: 13.63636364px 5px;
}
.page-container.sidebar-collapsed .sidebar-user-info .user-link {
  text-align: center;
}
.page-container.sidebar-collapsed .sidebar-user-info .user-link img {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform: scale(0.8, );
  -ms-transform: scale(0.8, );
  transform: scale(0.8, );
}
.page-container.sidebar-collapsed .sidebar-user-info .user-link span,
.page-container.sidebar-collapsed .sidebar-user-info .user-link strong {
  position: absolute;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
.page-container.sidebar-collapsed .sidebar-user-info .sui-hover {
  position: absolute;
  top: 0;
  left: 65px;
  width: 280px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #303641;
  border: 1px solid #454a54;
  border-left: 0;
}
.page-container.sidebar-collapsed .sidebar-user-info .sui-hover a {
  line-height: 1.2;
}
.page-container.sidebar-collapsed.sidebar-is-showing .sidebar-user-info {
  padding: 25px 35px;
}
.page-container.sidebar-collapsed.sidebar-is-showing .sidebar-user-info img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transform: scale(1, );
  -ms-transform: scale(1, );
  transform: scale(1, );
}
.page-container.sidebar-collapsed .sidebar-menu {
  z-index: 4;
  width: 65px;
}
.page-container.sidebar-collapsed .sidebar-menu .logo-env {
  display: block;
  padding: 20px;
}
.page-container.sidebar-collapsed .sidebar-menu .logo-env > div.logo {
  overflow: hidden;
  width: 0;
}
.page-container.sidebar-collapsed .sidebar-menu .logo-env > div.sidebar-collapse {
  display: block;
  padding: 0;
  left: 3px;
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li#search .search-input {
  position: absolute;
  visibility: hidden;
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li#search button {
  padding-left: 20px;
  padding-bottom: 20px;
  margin: 0;
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li#search.focused .search-input {
  display: block;
  position: absolute;
  visibility: visible;
  top: 0px;
  left: 65px;
  background: #262b34;
  border: 1px solid #454a54;
  width: 250px;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
  border-left: 0;
  padding: 15px 20px;
  padding-bottom: 16px;
  border: 0;
  padding-left: 0;
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li.has-sub > a:before {
  display: none;
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li.has-sub > a {
  background-color: transparent;
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li > a {
  position: relative;
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li > a > span:not(.badge) {
  display: block;
  position: absolute;
  top: 0;
  left: 65px;
  margin-top: -1px;
  padding: 12px 10px;
  background: #303641;
  border-top: 1px solid #454a54;
  border-bottom: 1px solid #454a54;
  width: 250px;
  visibility: hidden;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li > a span {
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li > a span.badge {
  position: absolute;
  right: -240px;
  top: 10px;
  display: none;
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li ul {
  border-top-color: #454a54;
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li > ul {
  display: block;
  visibility: hidden;
  position: absolute;
  height: 0;
  top: auto;
  left: 65px;
  width: 250px;
  -webkit-border-radius: 0 0 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 0 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 0 3px 0;
  background-clip: padding-box;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li > ul > li > a span.badge {
  display: none;
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li > ul li {
  border-bottom: 1px solid #454a54;
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li > ul li a {
  padding-left: 10px;
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li > ul li li a {
  padding-left: 25px;
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li > ul li li li a {
  padding-left: 40px;
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li > ul li li li li a {
  padding-left: 55px;
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li.has-sub:hover.has-sub > a,
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li:hover.has-sub > a {
  cursor: default;
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li.has-sub:hover.has-sub > a > span:not(.badge),
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li:hover.has-sub > a > span:not(.badge) {
  -webkit-border-radius: 0 3px 0 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 0 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 0 0;
  background-clip: padding-box;
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li.has-sub:hover.has-sub > a:hover,
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li:hover.has-sub > a:hover {
  color: #aaabae;
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li.has-sub:hover > ul,
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li:hover > ul {
  height: auto;
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li.has-sub:hover > a > span,
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li:hover > a > span {
  visibility: visible;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li.has-sub:hover a span.badge,
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li:hover a span.badge {
  display: block;
}
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li.has-sub:hover > ul,
.page-container.sidebar-collapsed .sidebar-menu #main-menu > li:hover > ul {
  visibility: visible;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}
.page-container.sidebar-is-collapsing .sidebar-user-info {
  padding: 13.63636364px 5px;
}
.page-container.sidebar-is-collapsing .sidebar-user-info img {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform: scale(0.8, );
  -ms-transform: scale(0.8, );
  transform: scale(0.8, );
}
.page-container.sidebar-is-collapsing .sidebar-menu #main-menu li#search .search-input {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.page-container.sidebar-is-collapsing .sidebar-menu #main-menu li a span {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  -moz-transition: opacity 250ms ease-in-out !important;
  -o-transition: opacity 250ms ease-in-out !important;
  -webkit-transition: opacity 250ms ease-in-out !important;
  transition: opacity 250ms ease-in-out !important;
}
.page-container.sidebar-is-showing .sidebar-menu #main-menu li#search .search-input {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
.page-container.sidebar-is-showing .sidebar-menu #main-menu li a:before {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
.page-container.sidebar-is-showing .sidebar-menu #main-menu li a span {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
.page-container.no-sidebar {
  padding-left: 0;
}
.page-container.no-sidebar .main-content {
  padding-left: 20px;
}
.page-container.horizontal-menu {
  padding-left: 0;
  padding-top: 62px;
}
.page-container.horizontal-menu.with-sidebar {
  padding-left: 280px;
}
.page-container.horizontal-menu.with-sidebar.sidebar-collapsed {
  padding-left: 65px;
}
.page-container.horizontal-menu.with-sidebar.fit-logo-with-sidebar header.navbar .navbar-brand {
  width: 280px;
}
.page-container.horizontal-menu.with-sidebar header.navbar {
  border-bottom: 1px solid #454a54;
}
.page-container.horizontal-menu.with-sidebar header.navbar ul.nav li.dropdown.open:after {
  bottom: -1px;
}
.page-container.horizontal-menu.with-sidebar header.navbar ul.nav li.dropdown.open .dropdown-menu {
  margin-top: 1px;
}
.page-container.horizontal-menu.with-sidebar .sidebar-user-info {
  border-top: 0;
  border-bottom: 1px solid #454a54;
}
.page-container.horizontal-menu.with-sidebar .sidebar-menu {
  top: 62px;
}
.page-container.horizontal-menu header.navbar {
  position: absolute;
  z-index: 8;
  background-color: #303641;
  padding: 0;
  margin-bottom: 0;
  border: 0;
  left: 0;
  right: 0;
  top: 0;
  -webkit-border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0;
  -moz-background-clip: padding;
  border-radius: 0;
  background-clip: padding-box;
}
.page-container.horizontal-menu header.navbar:before,
.page-container.horizontal-menu header.navbar:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.page-container.horizontal-menu header.navbar:after {
  clear: both;
}
.page-container.horizontal-menu header.navbar.navbar-fixed-top {
  position: fixed;
}
.page-container.horizontal-menu header.navbar > .navbar-inner:before,
.page-container.horizontal-menu header.navbar > .navbar-inner:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.page-container.horizontal-menu header.navbar > .navbar-inner:after {
  clear: both;
}
.page-container.horizontal-menu header.navbar .navbar-brand {
  padding: 20px 20px;
}
.page-container.horizontal-menu header.navbar .navbar-nav {
  border-left: 1px solid rgba(69, 74, 84, 0.5);
  margin: 0;
  padding: 0;
  list-style: none;
}
.page-container.horizontal-menu header.navbar .navbar-nav:before,
.page-container.horizontal-menu header.navbar .navbar-nav:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.page-container.horizontal-menu header.navbar .navbar-nav:after {
  clear: both;
}
.page-container.horizontal-menu header.navbar .navbar-nav > li {
  position: relative;
}
.page-container.horizontal-menu header.navbar .navbar-nav > li > a {
  position: relative;
  display: block;
  padding: 20px 20px;
  border-right: 1px solid rgba(69, 74, 84, 0.5);
  font-size: 12px;
  color: #aaabae;
  -moz-transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
  -o-transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
  -webkit-transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
  transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
  z-index: 10;
}
.page-container.horizontal-menu header.navbar .navbar-nav > li > a i {
  top: 1px;
}
.page-container.horizontal-menu header.navbar .navbar-nav > li > a span {
  font-size: 12px;
}
.page-container.horizontal-menu header.navbar .navbar-nav > li:hover > a {
  background-color: rgba(69, 74, 84, 0.3);
  color: #ffffff;
}
.page-container.horizontal-menu header.navbar .navbar-nav > li#search {
  border-right: 1px solid rgba(69, 74, 84, 0.5);
}
.page-container.horizontal-menu header.navbar .navbar-nav > li#search.search-input-collapsed:hover {
  background-color: rgba(69, 74, 84, 0.3);
}
.page-container.horizontal-menu header.navbar .navbar-nav > li.active > a {
  background-color: #2b303a;
  color: #ffffff;
}
.page-container.horizontal-menu header.navbar .navbar-nav > li.active.has-sub > a:after {
  position: absolute;
  content: '';
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #2b303a transparent transparent transparent;
  bottom: -6px;
  left: 50%;
  margin-left: -6px;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -moz-transition-delay: 300ms;
  -o-transition-delay: 300ms;
  -webkit-transition-delay: 300ms;
  transition-delay: 300ms;
}
.page-container.horizontal-menu header.navbar .navbar-nav > li.active.has-sub:hover > a:after {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}
.page-container.horizontal-menu header.navbar .navbar-nav > li .badge {
  margin-left: 10px;
  margin-right: -5px;
}
.page-container.horizontal-menu header.navbar .navbar-nav > li ul {
  position: absolute;
  list-style: none;
  margin: 0;
  padding: 0;
  left: 0;
  top: auto;
  margin-top: 1px;
  background-color: #272b34;
  min-width: 250px;
  display: none;
}
.page-container.horizontal-menu header.navbar .navbar-nav > li ul li {
  position: relative;
}
.page-container.horizontal-menu header.navbar .navbar-nav > li ul li a {
  display: block;
  padding: 10px 20px;
  white-space: nowrap;
  font-size: 12px;
  border-bottom: 1px solid #303641;
  color: #aaabae;
  -moz-transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
  -o-transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
  -webkit-transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
  transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
}
.page-container.horizontal-menu header.navbar .navbar-nav > li ul li.active > a {
  background-color: #2b303a;
  color: #ffffff;
}
.page-container.horizontal-menu header.navbar .navbar-nav > li ul li:hover > a {
  background-color: #2b303a;
  color: #ffffff;
}
.page-container.horizontal-menu header.navbar .navbar-nav > li ul li ul {
  position: absolute;
  left: 100%;
  top: -1px;
}
.page-container.horizontal-menu header.navbar .navbar-nav > li ul li.has-sub > a:before {
  position: relative;
  content: '\e877';
  content: '\e879';
  display: inline-block;
  font-family: 'Entypo';
  color: #aaabae;
  color: #FFF;
  float: right;
  font-size: 15px;
  margin-left: 20px;
  top: -1px;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.page-container.horizontal-menu header.navbar .navbar-nav > li ul li.has-sub:hover > a:before {
  color: #ffffff;
}
.page-container.horizontal-menu header.navbar .navbar-nav > li ul > li:last-child > a {
  border-bottom: 0;
}
.page-container.horizontal-menu header.navbar .navbar-inner > ul > li#search,
.page-container.horizontal-menu header.navbar > ul > li#search {
  position: relative;
  background-color: #262b34;
  -moz-transition: background 300ms ease-in-out;
  -o-transition: background 300ms ease-in-out;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
}
.page-container.horizontal-menu header.navbar .navbar-inner > ul > li#search ::-webkit-input-placeholder,
.page-container.horizontal-menu header.navbar > ul > li#search ::-webkit-input-placeholder {
  color: rgba(170, 171, 174, 0.7);
}
.page-container.horizontal-menu header.navbar .navbar-inner > ul > li#search :-moz-placeholder,
.page-container.horizontal-menu header.navbar > ul > li#search :-moz-placeholder {
  color: rgba(170, 171, 174, 0.7);
}
.page-container.horizontal-menu header.navbar .navbar-inner > ul > li#search ::-moz-placeholder,
.page-container.horizontal-menu header.navbar > ul > li#search ::-moz-placeholder {
  color: rgba(170, 171, 174, 0.7);
}
.page-container.horizontal-menu header.navbar .navbar-inner > ul > li#search :-ms-input-placeholder,
.page-container.horizontal-menu header.navbar > ul > li#search :-ms-input-placeholder {
  color: rgba(170, 171, 174, 0.7);
}
.page-container.horizontal-menu header.navbar .navbar-inner > ul > li#search .search-input,
.page-container.horizontal-menu header.navbar > ul > li#search .search-input {
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  padding: 22px 20px;
  padding-right: 45px;
  width: 220px;
  color: #aaabae;
  font-size: 12px;
  line-height: 1.42857143;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: all 350ms ease-in-out;
  -moz-transition: all 350ms ease-in-out;
  -o-transition: all 350ms ease-in-out;
  transition: all 350ms ease-in-out;
  -webkit-transition: all 350 ease-in-out;
  transition: all 350 ease-in-out;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.page-container.horizontal-menu header.navbar .navbar-inner > ul > li#search button,
.page-container.horizontal-menu header.navbar > ul > li#search button {
  position: absolute;
  right: 0;
  top: 0;
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  color: #aaabae;
  padding: 20px 10px;
  -webkit-transition: all 350ms ease-in-out;
  -moz-transition: all 350ms ease-in-out;
  -o-transition: all 350ms ease-in-out;
  transition: all 350ms ease-in-out;
  -webkit-transition: all 350 ease-in-out;
  transition: all 350 ease-in-out;
}
.page-container.horizontal-menu header.navbar .navbar-inner > ul > li#search button i,
.page-container.horizontal-menu header.navbar > ul > li#search button i {
  margin: 0;
}
.page-container.horizontal-menu header.navbar .navbar-inner > ul > li#search.search-input-collapsed,
.page-container.horizontal-menu header.navbar > ul > li#search.search-input-collapsed {
  background-color: transparent;
}
.page-container.horizontal-menu header.navbar .navbar-inner > ul > li#search.search-input-collapsed ::-webkit-input-placeholder,
.page-container.horizontal-menu header.navbar > ul > li#search.search-input-collapsed ::-webkit-input-placeholder {
  color: transparent;
}
.page-container.horizontal-menu header.navbar .navbar-inner > ul > li#search.search-input-collapsed :-moz-placeholder,
.page-container.horizontal-menu header.navbar > ul > li#search.search-input-collapsed :-moz-placeholder {
  color: transparent;
}
.page-container.horizontal-menu header.navbar .navbar-inner > ul > li#search.search-input-collapsed ::-moz-placeholder,
.page-container.horizontal-menu header.navbar > ul > li#search.search-input-collapsed ::-moz-placeholder {
  color: transparent;
}
.page-container.horizontal-menu header.navbar .navbar-inner > ul > li#search.search-input-collapsed :-ms-input-placeholder,
.page-container.horizontal-menu header.navbar > ul > li#search.search-input-collapsed :-ms-input-placeholder {
  color: transparent;
}
.page-container.horizontal-menu header.navbar .navbar-inner > ul > li#search.search-input-collapsed .search-input,
.page-container.horizontal-menu header.navbar > ul > li#search.search-input-collapsed .search-input {
  overflow: hidden;
  width: 45px;
  padding-left: 0;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
.page-container.horizontal-menu header.navbar .navbar-inner > ul > li#search.search-input-collapsed button,
.page-container.horizontal-menu header.navbar > ul > li#search.search-input-collapsed button {
  padding-right: 15px;
}
.page-container.horizontal-menu header.navbar ul.nav {
  color: #aaabae;
  font-size: 12px;
}
.page-container.horizontal-menu header.navbar ul.nav:before,
.page-container.horizontal-menu header.navbar ul.nav:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.page-container.horizontal-menu header.navbar ul.nav:after {
  clear: both;
}
.page-container.horizontal-menu header.navbar ul.nav > li {
  float: left;
}
.page-container.horizontal-menu header.navbar ul.nav > li > a,
.page-container.horizontal-menu header.navbar ul.nav > li > span {
  display: block;
  float: left;
  padding: 22px 20px;
  color: #aaabae;
  font-size: 12px;
  line-height: 1.42857143;
}
.page-container.horizontal-menu header.navbar ul.nav > li > a i,
.page-container.horizontal-menu header.navbar ul.nav > li > span i {
  margin-right: 5px;
}
.page-container.horizontal-menu header.navbar ul.nav > li > a i.right,
.page-container.horizontal-menu header.navbar ul.nav > li > span i.right {
  margin-right: 0;
  margin-left: 5px;
}
.page-container.horizontal-menu header.navbar ul.nav > li > a {
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.page-container.horizontal-menu header.navbar ul.nav > li > a:hover,
.page-container.horizontal-menu header.navbar ul.nav > li > a:focus {
  color: #ffffff;
  background-color: transparent;
}
.page-container.horizontal-menu header.navbar ul.nav > li.sep {
  float: left;
  display: block;
  border-left: 1px solid #454a54;
  margin: 22px 0;
  height: 16px;
  width: 0px;
  line-height: 1.42857143;
  overflow: hidden;
}
.page-container.horizontal-menu header.navbar ul.nav > li#search button {
  padding: 22px 20px;
}
.page-container.horizontal-menu header.navbar ul.nav > li#search.search-input-collapsed button {
  padding-right: 15px;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown > a {
  position: relative;
  display: block;
  padding: 0;
  margin: 10px;
  margin-top: 18px;
  margin-bottom: 16px;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown > a i {
  font-size: 19px;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown > a .badge {
  position: absolute;
  padding: 2px;
  font-size: 10px;
  right: 0px;
  top: -5px;
  min-width: 17px;
  line-height: 12px;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu {
  background-color: #f5f5f6;
  -webkit-border-radius: 0 0 3px 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 0 3px 3px;
  -moz-background-clip: padding;
  border-radius: 0 0 3px 3px;
  background-clip: padding-box;
  -moz-box-shadow: 0 1px 0px rgba(0,0,0,.05);
  -webkit-box-shadow: 0 1px 0px rgba(0,0,0,.05);
  box-shadow: 0 1px 0px rgba(0,0,0,.05);
  border: 1px solid #f8f8f8;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 0px;
  width: 370px;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu li,
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu a,
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu p {
  color: #888888;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li {
  display: block;
  border-bottom: 1px solid #ededed;
  margin: 0;
  padding: 0;
  font-size: 12px;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li.top > p {
  background-color: #f5f5f6;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > a,
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > p {
  margin: 0;
  padding: 12px 20px;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > p {
  background-color: #FFF;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > p a {
  color: #ec5956;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > p a:hover {
  color: #e9423f;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li .scroller {
  max-height: 290px;
  overflow: auto;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li {
  font-size: 12px;
  border-bottom: 1px solid #ededed;
  background: #f5f5f6;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li > a,
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li > p {
  display: block;
  position: relative;
  margin: 0;
  padding: 10px 20px;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li > a:before,
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li > p:before,
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li > a:after,
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li > p:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li > a:after,
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li > p:after {
  clear: both;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li > a > i,
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li > p > i {
  background: #454a54;
  display: block;
  float: left;
  text-align: center;
  padding: 5px;
  line-height: 14px;
  color: #ffffff;
  width: 28px;
  height: 24px;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li > a:hover,
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li > p:hover {
  background-color: #f5f5f6 !important;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li.notification-primary > i,
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li.notification-primary > a > i {
  background-color: #303641;
  color: #ffffff;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li.notification-secondary > i,
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li.notification-secondary > a > i {
  background-color: #ee4749;
  color: #ffffff;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li.notification-success > i,
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li.notification-success > a > i {
  background-color: #00a651;
  color: #ffffff;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li.notification-info > i,
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li.notification-info > a > i {
  background-color: #21a9e1;
  color: #ffffff;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li.notification-warning > i,
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li.notification-warning > a > i {
  background-color: #fad839;
  color: #ffffff;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li.notification-danger > i,
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li.notification-danger > a > i {
  background-color: #cc2424;
  color: #ffffff;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li .task {
  display: block;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li .task:before,
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li .task:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li .task:after {
  clear: both;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li .task span {
  display: block;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li .task span.desc {
  float: left;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li .task span.percent {
  float: right;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li .image {
  display: block;
  margin-right: 10px;
  float: left;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li .image.pull-right {
  margin-left: 10px;
  margin-right: 0;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li .image .img-circle {
  border: 2px solid #FFF;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li .image + .line {
  margin-top: 8px;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li .line {
  display: block;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li .line.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li .line + .line,
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li .line + .line + .line,
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li .line + .line + .line + .line {
  margin-top: 5px;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li strong {
  color: #373e4a;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li .progress {
  display: block;
  height: 5px;
  background-color: #ededed;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 5px;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li .progress:before,
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li .progress:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li .progress:after {
  clear: both;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li:last-child {
  border-bottom: 0;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li > ul > li:first-child a:afterx {
  position: absolute;
  content: '';
  display: block;
  height: 10px;
  top: -10px;
  left: 0;
  right: 0;
  background: transparent;
  background: red;
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.03);
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li.external {
  background-color: #FFF;
  border-bottom: 0;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li.external a {
  color: #ec5956;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu > li.external a:hover {
  background-color: #fff;
  color: #e9423f;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown.open {
  background-color: rgba(69, 74, 84, 0.5);
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown.open > a {
  background-color: transparent;
  color: #ffffff;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown.open:after {
  content: '';
  position: absolute;
  display: block;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 7px 7px 7px;
  border-color: transparent transparent #ffffff transparent;
  bottom: -2px;
  left: 50%;
  margin-left: -7px;
}
.page-container.horizontal-menu header.navbar ul.nav > li .horizontal-mobile-menu {
  position: relative;
}
.page-container.horizontal-menu header.navbar ul.nav > li .horizontal-mobile-menu a {
  display: inline-block;
  border: 1px solid #454a54;
  text-align: center;
  padding: 0;
  line-height: 1;
  font-size: 20px;
  font-weight: 300;
  padding: 5px 2px;
  color: #aaabae;
  margin-top: 15px;
  margin-left: 20px;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}
.page-container.horizontal-menu header.navbar ul.nav > li .horizontal-mobile-menu a:hover {
  background-color: rgba(69, 74, 84, 0.4);
}
/* Lower screen than large screen */
@media (max-width: 992px) {
  .page-body .page-container.horizontal-menu.fit-logo-with-sidebar header.navbar .navbar-brand {
    width: auto;
  }
}
@media (min-width: 767px) and (max-width: 1100px) {
  .page-container.horizontal-menu header.navbar .navbar-nav > li > a {
    padding-left: 10px;
    padding-right: 10px;
    -moz-transition: padding 250ms ease-in-out;
    -o-transition: padding 250ms ease-in-out;
    -webkit-transition: padding 250ms ease-in-out;
    transition: padding 250ms ease-in-out;
  }
  .page-container.horizontal-menu header.navbar .navbar-nav > li > a .badge {
    display: none;
  }
  .page-container.horizontal-menu header.navbar > ul > li#search .search-input,
  .page-container.horizontal-menu header.navbar .navbar-inner > ul > li#search .search-input {
    width: 140px;
  }
  .page-container.horizontal-menu header.navbar .navbar-nav > li ul li a {
    padding: 10px 15px;
  }
}
/* Tablets Only */
@media (min-width: 767px) and (max-width: 992px) {
  .page-container.horizontal-menu header.navbar .navbar-nav > li > a {
    padding-left: 15px;
    padding-right: 15px;
  }
  .page-container.horizontal-menu header.navbar .navbar-nav > li > a i + span {
    display: none;
  }
  .page-container.Xhorizontal-menu header.navbar .navbar-nav li a,
  .page-container.Xhorizontal-menu header.navbar .navbar-nav li ul li a,
  .page-container.Xhorizontal-menu header.navbar ul.nav > li > a {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 11px;
  }
  .page-container.Xhorizontal-menu header.navbar ul > li#search .search-input {
    width: 150px;
  }
  .page-container.Xhorizontal-menu header.navbar ul.nav > li.dropdown .dropdown-menu {
    width: 315px;
  }
}
/* Relatively-Small screen */
@media (max-width: 767px) {
  .page-body .page-container {
    padding-left: 0;
  }
  .page-body .page-container .sidebar-menu {
    position: relative;
    width: 100%;
    min-height: 0px !important;
  }
  .page-body .page-container .sidebar-menu .logo-env {
    padding: 15px 20px;
    -moz-transition: all padding ease-in-out;
    -o-transition: all padding ease-in-out;
    -webkit-transition: all padding ease-in-out;
    transition: all padding ease-in-out;
  }
  .page-body .page-container .sidebar-menu .logo-env > div.logo {
    display: block;
    width: auto;
  }
  .page-body .page-container .sidebar-menu .logo-env > div.sidebar-collapse {
    display: none;
  }
  .page-body .page-container .sidebar-menu #main-menu {
    display: none;
    margin-bottom: 0;
  }
  .page-body .page-container .sidebar-menu #main-menu li a span {
    -moz-transition: none;
    -o-transition: none;
    -webkit-transition: none;
    transition: none;
  }
  .page-body .page-container .main-content {
    min-height: auto !important;
  }
  .page-body .page-container.horizontal-menu {
    padding-top: 0;
  }
  .page-body .page-container.horizontal-menu.with-sidebar {
    padding-left: 0;
  }
  .page-body .page-container.horizontal-menu header.navbar {
    position: relative;
  }
  .page-body .page-container.horizontal-menu header.navbar .navbar-nav {
    clear: left;
    border-top: 1px solid rgba(69, 74, 84, 0.4);
    display: none;
  }
  .page-body .page-container.horizontal-menu header.navbar .navbar-nav > li > a {
    border-right: 0;
    border-bottom: 1px solid rgba(69, 74, 84, 0.4);
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .page-body .page-container.horizontal-menu header.navbar .navbar-nav > li#search {
    border-right: 0;
    background-color: #262b34;
    border-bottom: 1px solid rgba(69, 74, 84, 0.4);
  }
  .page-body .page-container.horizontal-menu header.navbar .navbar-nav > li#search ::-webkit-input-placeholder {
    color: #aaabae;
  }
  .page-body .page-container.horizontal-menu header.navbar .navbar-nav > li#search :-moz-placeholder {
    color: #aaabae;
  }
  .page-body .page-container.horizontal-menu header.navbar .navbar-nav > li#search ::-moz-placeholder {
    color: #aaabae;
  }
  .page-body .page-container.horizontal-menu header.navbar .navbar-nav > li#search :-ms-input-placeholder {
    color: #aaabae;
  }
  .page-body .page-container.horizontal-menu header.navbar .navbar-nav > li#search:hover {
    background-color: #262b34;
  }
  .page-body .page-container.horizontal-menu header.navbar .navbar-nav > li#search .search-input {
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
    width: 100%;
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .page-body .page-container.horizontal-menu header.navbar .navbar-nav > li#search button {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .page-body .page-container.horizontal-menu header.navbar .navbar-nav > li > ul > li > a:first-child {
    border-top: 0;
  }
  .page-body .page-container.horizontal-menu header.navbar .navbar-nav > li ul {
    overflow: hidden;
    width: 100%;
    position: relative;
    left: 0;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -o-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
  }
  .page-body .page-container.horizontal-menu header.navbar .navbar-nav > li ul.visible {
    display: block !important;
    visibility: visible !important;
    opacity: 1 !important;
  }
  .page-body .page-container.horizontal-menu header.navbar .navbar-nav > li ul li a {
    border: none;
    border-bottom: 1px solid rgba(69, 74, 84, 0.3);
  }
  .page-body .page-container.horizontal-menu header.navbar .navbar-nav > li ul li:hover > a {
    background: transparent;
  }
  .page-body .page-container.horizontal-menu header.navbar .navbar-nav > li ul li > a:hover {
    background-color: rgba(69, 74, 84, 0.2);
  }
  .page-body .page-container.horizontal-menu header.navbar .navbar-nav > li ul li.active > a {
    background-color: rgba(69, 74, 84, 0.15);
    color: #ffffff;
  }
  .page-body .page-container.horizontal-menu header.navbar .navbar-nav > li ul li.opened > a:before {
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .page-body .page-container.horizontal-menu header.navbar .navbar-nav > li ul li a {
    padding-left: 40px;
  }
  .page-body .page-container.horizontal-menu header.navbar .navbar-nav > li ul li li a {
    padding-left: 60px;
  }
  .page-body .page-container.horizontal-menu header.navbar .navbar-nav > li ul li li li a {
    padding-left: 80px;
  }
  .page-body .page-container.horizontal-menu header.navbar ul.nav {
    position: absolute;
    top: 0px;
    right: 20px;
  }
  .page-body .page-container.horizontal-menu header.navbar ul.nav > li {
    display: none;
  }
  .page-body .page-container.horizontal-menu header.navbar ul.nav > li.dropdown {
    display: block;
  }
  .page-body .page-container.horizontal-menu header.navbar ul.nav > li.dropdown.open > .dropdown-menu {
    width: 259px;
    margin-left: -185px;
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box;
  }
  .page-body .page-container .main-content {
    min-height: 0 !important;
  }
}
/* Small Screen */
@media (max-width: 480px) {
  .page-body .page-container.horizontal-menu header.navbar .horizontal-mobile-menu a {
    margin-left: 10px;
  }
  .page-body .page-container.horizontal-menu header.navbar ul.nav > li.dropdown > a {
    margin-left: 5px;
    margin-right: 5px;
  }
  .page-body .page-container.horizontal-menu header.navbar ul.nav > li.dropdown.open > .dropdown-menu {
    width: 222px;
    margin-left: -123.33333333px;
  }
}
/* Right Sidebar Menu */
.page-container.right-sidebar .sidebar-menu .sidebar-user-info .sui-hover.inline-links a {
  margin-left: 10px;
}
.page-container.right-sidebar .sidebar-menu .sidebar-user-info .sui-hover .close-sui-popup {
  right: auto;
  left: 15px;
}
@media screen and (min-width: 991px) {
  .page-container.right-sidebar {
    padding-left: 0;
    padding-right: 280px;
  }
  .page-container.right-sidebar .sidebar-menu {
    left: auto;
    right: 0;
  }
  .page-container.right-sidebar.sidebar-collapsed {
    padding-left: 0;
    padding-right: 65px;
  }
  .page-container.right-sidebar.sidebar-collapsed .sidebar-menu #main-menu > li > a > span,
  .page-container.right-sidebar.sidebar-collapsed .sidebar-menu #main-menu > li > ul {
    left: auto;
    right: 65px;
  }
  .page-container.right-sidebar.sidebar-collapsed .sidebar-menu #main-menu > li > a > i {
    margin-right: 0;
  }
  .page-container.right-sidebar.sidebar-collapsed .sidebar-menu #main-menu > li > a > span.badge {
    right: 75px;
    left: auto;
  }
  .page-container.right-sidebar.sidebar-collapsed .sidebar-menu #main-menu > li > a > span:not(.badge) {
    -webkit-border-radius: 3px 0 0 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px 0 0 3px;
    -moz-background-clip: padding;
    border-radius: 3px 0 0 3px;
    background-clip: padding-box;
    padding-left: 15px;
  }
  .page-container.right-sidebar.sidebar-collapsed .sidebar-menu #main-menu > li > ul li a {
    padding-left: 15px;
    padding-right: 10px;
  }
  .page-container.right-sidebar.sidebar-collapsed .sidebar-menu #main-menu > li > ul li a i {
    margin-right: 0;
    margin-left: 5px;
  }
  .page-container.right-sidebar.sidebar-collapsed .sidebar-menu #main-menu > li > ul {
    right: 65px;
    left: auto;
  }
  .page-container.right-sidebar.sidebar-collapsed .sidebar-menu #main-menu > li > ul {
    -webkit-border-radius: 0 0 0 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 0 0 3px;
    -moz-background-clip: padding;
    border-radius: 0 0 0 3px;
    background-clip: padding-box;
  }
  .page-container.right-sidebar.sidebar-collapsed .sidebar-menu #main-menu > li#search.focused .search-input {
    left: auto;
    right: 65px;
    -webkit-border-radius: 3px 0 0 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px 0 0 3px;
    -moz-background-clip: padding;
    border-radius: 3px 0 0 3px;
    background-clip: padding-box;
  }
  .page-container.right-sidebar.horizontal-menu.with-sidebar {
    padding-left: 0;
    padding-right: 280px;
  }
  .page-container.right-sidebar.horizontal-menu.with-sidebar.sidebar-collapsed {
    padding-left: 0;
    padding-right: 65px;
  }
  .page-container.right-sidebar.chat-visible .main-content {
    padding-left: 300px;
    padding-right: 20px;
  }
  .page-container.right-sidebar #chat {
    left: 0;
    right: auto;
  }
  .page-container.right-sidebar #chat .chat-conversation {
    left: 280px;
    right: auto;
    -webkit-border-radius: 0 3px 3px 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 0 3px 3px 0;
    -moz-background-clip: padding;
    border-radius: 0 3px 3px 0;
    background-clip: padding-box;
  }
  .page-container.right-sidebar.sidebar-is-collapsing .sidebar-menu #main-menu li a i {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  .page-container.right-sidebar.sidebar-is-collapsing .sidebar-menu {
    -moz-transition: all 350ms;
    -o-transition: all 350ms;
    -webkit-transition: all 350ms;
    transition: all 350ms;
  }
  .page-container.right-sidebar.sidebar-is-collapsing .sidebar-menu .sidebar-user-info .user-link span,
  .page-container.right-sidebar.sidebar-is-collapsing .sidebar-menu .sidebar-user-info .user-link strong {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  .page-container.right-sidebar.sidebar-is-collapsing.sidebar-collapsing-phase-2 {
    padding-right: 65px;
    -moz-transition: all 600ms;
    -o-transition: all 600ms;
    -webkit-transition: all 600ms;
    transition: all 600ms;
  }
  .page-container.right-sidebar.sidebar-is-collapsing.sidebar-collapsing-phase-2 .sidebar-menu {
    width: 65px;
  }
  .page-container.right-sidebar.sidebar-is-collapsing.sidebar-collapsing-phase-2 .sidebar-menu .logo-env > .logo,
  .page-container.right-sidebar.sidebar-is-collapsing.sidebar-collapsing-phase-2 .sidebar-menu #main-menu li > a:before,
  .page-container.right-sidebar.sidebar-is-collapsing.sidebar-collapsing-phase-2 .sidebar-menu #main-menu li a span,
  .page-container.right-sidebar.sidebar-is-collapsing.sidebar-collapsing-phase-2 .sidebar-menu .sidebar-user-info .user-link span,
  .page-container.right-sidebar.sidebar-is-collapsing.sidebar-collapsing-phase-2 .sidebar-menu .sidebar-user-info .user-link strong {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
    display: none;
  }
  .page-container.right-sidebar.sidebar-collapsed .sidebar-menu #main-menu > li.has-sub:hover.has-sub > a > span:not(.badge),
  .page-container.right-sidebar.sidebar-collapsed .sidebar-menu #main-menu > li:hover.has-sub > a > span:not(.badge) {
    -webkit-border-radius: 3px 0 0 0;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px 0 0 0;
    -moz-background-clip: padding;
    border-radius: 3px 0 0 0;
    background-clip: padding-box;
  }
}
.user-info {
  list-style: none;
  margin: 0;
  padding: 0;
}
.user-info:before,
.user-info:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.user-info:after {
  clear: both;
}
.user-info > li {
  float: left;
  margin-right: 2.5px;
}
.user-info > li.profile-info {
  margin-right: 10px;
}
.profile-info.dropdown > a {
  color: #2b303a;
  text-decoration: none;
  font-size: 14px;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.profile-info.dropdown > a:hover {
  color: #818da2;
}
.profile-info.dropdown > a img {
  border: 2px solid #f5f5f5;
  margin-right: 5px;
}
.profile-info.dropdown .dropdown-menu {
  background-color: #303641;
  border-color: #454a54;
  padding: 0;
  margin-top: 15px;
}
.profile-info.dropdown .dropdown-menu > .caret {
  position: absolute;
  left: 20px;
  top: -4px;
  border-bottom-width: 4px;
  border-top-width: 0;
  border-bottom-color: #454a54;
  margin-left: -2px;
}
.profile-info.dropdown .dropdown-menu li {
  color: #aaabae;
  font-size: 12px;
  padding: 0;
}
.profile-info.dropdown .dropdown-menu li a {
  color: #aaabae;
  padding: 8px 10px;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.profile-info.dropdown .dropdown-menu li a i {
  margin-right: 5px;
  -webkit-opacity: 0.7;
  -moz-opacity: 0.7;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.profile-info.dropdown .dropdown-menu li a:hover {
  background-color: #2b303a;
  color: #ffffff;
}
.profile-info.dropdown .dropdown-menu li a:hover i {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}
.profile-info.dropdown .dropdown-menu > li {
  border-bottom: 1px solid #454a54;
}
.profile-info.dropdown .dropdown-menu > li > a {
  display: block;
  min-width: 210px;
}
.profile-info.dropdown .dropdown-menu > li:last-child {
  border-bottom: 0;
}
.profile-info.dropdown.pull-right {
  text-align: right;
}
.profile-info.dropdown.pull-right > a img {
  margin-left: 5px;
  margin-right: 0;
}
.profile-info.dropdown.pull-right .dropdown-menu {
  background-color: #303641;
  border-color: #454a54;
  padding: 0;
  margin-top: 15px;
  text-align: left;
}
.profile-info.dropdown.pull-right .dropdown-menu > .caret {
  left: auto;
  right: 20px;
}
.notifications.dropdown > a {
  position: relative;
  display: block;
  padding: 10px 10px;
  color: #737881;
}
.notifications.dropdown > a i {
  font-size: 19px;
}
.notifications.dropdown > a .badge {
  position: absolute;
  padding: 2px;
  font-size: 10px;
  right: 6px;
  top: 6px;
  min-width: 17px;
  line-height: 12px;
}
.notifications.dropdown.open > a {
  background-color: #f5f5f6;
  -webkit-border-radius: 3px 3px 0 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 3px 0 0;
  -moz-background-clip: padding;
  border-radius: 3px 3px 0 0;
  background-clip: padding-box;
}
.notifications.dropdown .dropdown-menu {
  background-color: #f5f5f6;
  -webkit-border-radius: 0 0 3px 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 0 3px 3px;
  -moz-background-clip: padding;
  border-radius: 0 0 3px 3px;
  background-clip: padding-box;
  -moz-box-shadow: 0 1px 0px rgba(0,0,0,.05);
  -webkit-box-shadow: 0 1px 0px rgba(0,0,0,.05);
  box-shadow: 0 1px 0px rgba(0,0,0,.05);
  border: 1px solid #f8f8f8;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 0px;
  width: 370px;
}
.notifications.dropdown .dropdown-menu li,
.notifications.dropdown .dropdown-menu a,
.notifications.dropdown .dropdown-menu p {
  color: #888888;
}
.notifications.dropdown .dropdown-menu > li {
  display: block;
  border-bottom: 1px solid #ededed;
  margin: 0;
  padding: 0;
  font-size: 12px;
}
.notifications.dropdown .dropdown-menu > li.top > p {
  background-color: #f5f5f6;
}
.notifications.dropdown .dropdown-menu > li > a,
.notifications.dropdown .dropdown-menu > li > p {
  margin: 0;
  padding: 12px 20px;
}
.notifications.dropdown .dropdown-menu > li > p {
  background-color: #FFF;
}
.notifications.dropdown .dropdown-menu > li > p a {
  color: #ec5956;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.notifications.dropdown .dropdown-menu > li > p a:hover {
  color: #e9423f;
}
.notifications.dropdown .dropdown-menu > li .scroller {
  max-height: 290px;
  overflow: auto;
}
.notifications.dropdown .dropdown-menu > li > ul {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}
.notifications.dropdown .dropdown-menu > li > ul > li {
  font-size: 12px;
  border-bottom: 1px solid #ededed;
  background: #f5f5f6;
}
.notifications.dropdown .dropdown-menu > li > ul > li > a,
.notifications.dropdown .dropdown-menu > li > ul > li > p {
  display: block;
  position: relative;
  margin: 0;
  padding: 10px 20px;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.notifications.dropdown .dropdown-menu > li > ul > li > a:before,
.notifications.dropdown .dropdown-menu > li > ul > li > p:before,
.notifications.dropdown .dropdown-menu > li > ul > li > a:after,
.notifications.dropdown .dropdown-menu > li > ul > li > p:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.notifications.dropdown .dropdown-menu > li > ul > li > a:after,
.notifications.dropdown .dropdown-menu > li > ul > li > p:after {
  clear: both;
}
.notifications.dropdown .dropdown-menu > li > ul > li > a > i,
.notifications.dropdown .dropdown-menu > li > ul > li > p > i {
  background: #454a54;
  display: block;
  float: left;
  text-align: center;
  padding: 5px;
  line-height: 14px;
  color: #ffffff;
  width: 28px;
  height: 24px;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
}
.notifications.dropdown .dropdown-menu > li > ul > li > a:hover,
.notifications.dropdown .dropdown-menu > li > ul > li > p:hover {
  background-color: #f5f5f6 !important;
}
.notifications.dropdown .dropdown-menu > li > ul > li.notification-primary > i,
.notifications.dropdown .dropdown-menu > li > ul > li.notification-primary > a > i {
  background-color: #303641;
  color: #ffffff;
}
.notifications.dropdown .dropdown-menu > li > ul > li.notification-secondary > i,
.notifications.dropdown .dropdown-menu > li > ul > li.notification-secondary > a > i {
  background-color: #ee4749;
  color: #ffffff;
}
.notifications.dropdown .dropdown-menu > li > ul > li.notification-success > i,
.notifications.dropdown .dropdown-menu > li > ul > li.notification-success > a > i {
  background-color: #00a651;
  color: #ffffff;
}
.notifications.dropdown .dropdown-menu > li > ul > li.notification-info > i,
.notifications.dropdown .dropdown-menu > li > ul > li.notification-info > a > i {
  background-color: #21a9e1;
  color: #ffffff;
}
.notifications.dropdown .dropdown-menu > li > ul > li.notification-warning > i,
.notifications.dropdown .dropdown-menu > li > ul > li.notification-warning > a > i {
  background-color: #fad839;
  color: #ffffff;
}
.notifications.dropdown .dropdown-menu > li > ul > li.notification-danger > i,
.notifications.dropdown .dropdown-menu > li > ul > li.notification-danger > a > i {
  background-color: #cc2424;
  color: #ffffff;
}
.notifications.dropdown .dropdown-menu > li > ul > li .task {
  display: block;
}
.notifications.dropdown .dropdown-menu > li > ul > li .task:before,
.notifications.dropdown .dropdown-menu > li > ul > li .task:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.notifications.dropdown .dropdown-menu > li > ul > li .task:after {
  clear: both;
}
.notifications.dropdown .dropdown-menu > li > ul > li .task span {
  display: block;
}
.notifications.dropdown .dropdown-menu > li > ul > li .task span.desc {
  float: left;
}
.notifications.dropdown .dropdown-menu > li > ul > li .task span.percent {
  float: right;
}
.notifications.dropdown .dropdown-menu > li > ul > li .image {
  display: block;
  margin-right: 10px;
  float: left;
}
.notifications.dropdown .dropdown-menu > li > ul > li .image.pull-right {
  margin-left: 10px;
  margin-right: 0;
}
.notifications.dropdown .dropdown-menu > li > ul > li .image .img-circle {
  border: 2px solid #FFF;
}
.notifications.dropdown .dropdown-menu > li > ul > li .image + .line {
  margin-top: 8px;
}
.notifications.dropdown .dropdown-menu > li > ul > li .line {
  display: block;
}
.notifications.dropdown .dropdown-menu > li > ul > li .line.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.notifications.dropdown .dropdown-menu > li > ul > li .line + .line,
.notifications.dropdown .dropdown-menu > li > ul > li .line + .line + .line,
.notifications.dropdown .dropdown-menu > li > ul > li .line + .line + .line + .line {
  margin-top: 5px;
}
.notifications.dropdown .dropdown-menu > li > ul > li strong {
  color: #373e4a;
}
.notifications.dropdown .dropdown-menu > li > ul > li .progress {
  display: block;
  height: 5px;
  background-color: #ededed;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 5px;
}
.notifications.dropdown .dropdown-menu > li > ul > li .progress:before,
.notifications.dropdown .dropdown-menu > li > ul > li .progress:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.notifications.dropdown .dropdown-menu > li > ul > li .progress:after {
  clear: both;
}
.notifications.dropdown .dropdown-menu > li > ul > li:last-child {
  border-bottom: 0;
}
.notifications.dropdown .dropdown-menu > li > ul > li:first-child a:afterx {
  position: absolute;
  content: '';
  display: block;
  height: 10px;
  top: -10px;
  left: 0;
  right: 0;
  background: transparent;
  background: red;
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.03);
}
.notifications.dropdown .dropdown-menu > li.external {
  background-color: #FFF;
  border-bottom: 0;
}
.notifications.dropdown .dropdown-menu > li.external a {
  color: #ec5956;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.notifications.dropdown .dropdown-menu > li.external a:hover {
  background-color: #fff;
  color: #e9423f;
}
body {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}
body.overflow-x-disabled {
  overflow-x: hidden;
}
a {
  color: #373e4a;
}
a:hover {
  text-decoration: none;
  color: #818da2;
}
.links-list {
  white-space: nowrap;
  padding-top: 14px;
  padding-bottom: 8px;
}
.links-list > li.sep {
  display: inline-block;
  border-left: 1px solid #eeeef0;
  height: 12px;
  width: 0px;
  line-height: 1.42857143;
  overflow: hidden;
  margin-left: 10px;
}
.scrollable {
  position: relative;
  overflow: hidden;
  height: 0;
}
.slimScrollDiv .slimScrollBar {
  cursor: pointer;
}
.clear {
  clear: both;
}
.knob {
  visibility: hidden;
}
canvas + .knob {
  visibility: visible;
}
.validate-has-error {
  border-color: #cc2424;
}
.validate-has-error:focus {
  border-color: #cc2424;
}
.validate-has-error + span.validate-has-error,
.validate-has-error > span.validate-has-error,
.input-group + .validate-has-error {
  color: #cc2424;
  display: inline-block;
  margin-top: 5px;
}
.validate-has-error .validate-has-error {
  color: #cc2424;
  display: inline-block;
  margin-top: 5px;
}
.make-switch + .validate-has-error {
  margin-left: 10px;
}
.validate-has-error .form-control {
  border-color: #cc2424;
}
.file-input-wrapper.inline {
  display: inline-block;
  width: auto;
}
.file2[type="file"] {
  visibility: hidden;
}
.file-input-wrapper .file2[type="file"] {
  visibility: visible;
}
.dist > * {
  margin-bottom: 10px;
}
.dist > *:before,
.dist > *:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.dist > *:after {
  clear: both;
}
.dist > *:last-child {
  margin-bottom: 0;
}
@media print {
  .sidebar-menu {
    display: none !important;
  }
  .page-container.horizontal-menu {
    padding-top: 0;
  }
}
.nicescroll-rails > div {
  cursor: pointer;
}
.showcase-icon-list .icon-el a {
  position: relative;
  z-index: 1;
  background: #fafafa;
  margin-bottom: 6px;
  display: block;
  padding-right: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
}
.showcase-icon-list .icon-el a i {
  margin-right: 5px;
  display: inline-block;
  padding: 6px 12px;
  vertical-align: middle;
  -webkit-border-radius: 3px 0 0 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px;
  background-clip: padding-box;
  background: #f0f0f0;
  font-size: 15px;
  width: 45px;
  text-align: center;
}
.showcase-icon-list .icon-el a:hover {
  z-index: 5;
  overflow: visible;
  padding: 5px 12px;
}
.showcase-icon-list .icon-el a:hover i {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 60px;
  line-height: 50px;
  margin-right: 0px;
  color: #303641;
  font-size: 40px;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
}
footer.main {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #ebebeb;
}
footer.main:before,
footer.main:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
footer.main:after {
  clear: both;
}
.padding-top-none {
  padding-top: 0 !important;
}
.padding-bottom-none {
  padding-bottom: 0 !important;
}
.margin-top-none {
  margin-top: 0 !important;
}
.padding-bottom-none {
  padding-bottom: 0 !important;
}
.margin-left {
  margin-left: 10px !important;
}
.margin-bottom {
  margin-bottom: 20px !important;
}
.bold {
  font-weight: bold !important;
}
.default-padding {
  padding: 0 15px !important;
}
img.full-width {
  width: 100%;
}
.form-groups-bordered > .form-group {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 15px;
  padding-top: 15px;
  margin-bottom: 0px;
}
.form-groups-bordered > .form-group:first-child {
  padding-top: 0;
}
.form-groups-bordered > .form-group:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
.morrischart {
  width: 100%;
}
.morrischart > svg {
  width: 100% !important;
}
.col-padding-1 {
  padding: 20px 20px 20px 40px !important;
}
.no-margin {
  margin: 0 !important;
}
.padding-lg {
  padding: 20px !important;
}
.border-top {
  border-top: 1px solid #ebebeb;
}
#rickshaw-chart-demo {
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
  overflow: hidden;
  padding-top: 200px;
}
#rickshaw-chart-demo svg {
  width: 100% !important;
}
#rickshaw-chart-demo .detail {
  left: -10px;
}
#rickshaw-chart-demo .rickshaw_legend {
  position: absolute;
  top: 0px;
  background: none;
}
#rickshaw-chart-demo .rickshaw_legend .label {
  color: rgba(48, 54, 65, 0.5);
}
#rickshaw-chart-demo-2 {
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
  overflow: hidden;
  padding-top: 75px;
}
#rickshaw-chart-demo-2 svg {
  width: 100% !important;
}
#rickshaw-chart-demo-2 .detail {
  left: -10px;
}
#rickshaw-chart-demo-2 .rickshaw_legend {
  position: absolute;
  top: 0px;
  background: none;
}
#rickshaw-chart-demo-2 .rickshaw_legend .label {
  color: rgba(48, 54, 65, 0.5);
}
.theme-skins {
  background: #f5f5f6;
  border: none;
  margin: 0;
  padding: 0;
  margin-top: 12px;
  -webkit-border-radius: 2px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 2px;
  -moz-background-clip: padding;
  border-radius: 2px;
  background-clip: padding-box;
}
.theme-skins > li {
  border-bottom: 1px solid #ebebeb;
}
.theme-skins > li a {
  color: #555 !important;
  margin: 0;
  padding: 5px 10px;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.theme-skins > li a i {
  display: inline-block;
  width: 15px;
  height: 13px;
  margin-right: 5px;
  vertical-align: middle;
  border: 1px solid transparent;
  position: relative;
  top: -2px;
}
.theme-skins > li a i.skin-main {
  background-color: #303641;
}
.theme-skins > li a i.skin-blue {
  background-color: #003471;
}
.theme-skins > li a i.skin-red {
  background-color: #981b1b;
}
.theme-skins > li a i.skin-black {
  background-color: #222222;
}
.theme-skins > li a i.skin-green {
  background-color: #00a651;
}
.theme-skins > li a i.skin-purple {
  background-color: #522b76;
}
.theme-skins > li a i.skin-yellow {
  background-color: #e8b51b;
}
.theme-skins > li a i.skin-cafe {
  background-color: #31271e;
}
.theme-skins > li a i.skin-white {
  background-color: #FFF;
  border-color: #ebebeb;
}
.theme-skins > li a:hover {
  background: rgba(235, 235, 235, 0.4);
}
.theme-skins > li.active a {
  -webkit-border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0;
  -moz-background-clip: padding;
  border-radius: 0;
  background-clip: padding-box;
}
.theme-skins > li.caret {
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  position: absolute;
  top: -4px;
  left: 57%;
  margin-left: -4px;
  border-top-color: #ebebeb;
  display: none;
}
.theme-skins > li.active a {
  background: rgba(235, 235, 235, 0.9);
  color: #737881;
  font-weight: bold;
}
.theme-skins > li.active a:hover {
  background: rgba(235, 235, 235, 0.9);
}
.theme-skins > li:last-child {
  border-bottom: 0;
}
.page-container.horizontal-menu header.navbar ul.nav > li.dropdown .theme-skins.dropdown-menu {
  width: 200px !important;
}
.version-highlights {
  margin: 0;
  padding: 0;
  border-top: 1px solid #ebebeb;
  margin-top: 30px;
  list-style: none;
  display: table;
}
.version-highlights li {
  display: table-row;
  vertical-align: middle;
  width: 100%;
  padding: 0;
}
.version-highlights li .screenshot,
.version-highlights li .notes {
  display: table-cell;
  vertical-align: middle;
}
.version-highlights li .screenshot {
  text-align: right;
  padding: 30px;
  width: 240px;
}
.version-highlights li .screenshot img {
  display: block;
  max-width: 100%;
  height: auto;
}
.thumbnail-highlight {
  border: 5px solid rgba(235, 235, 235, 0.5);
  -moz-box-shadow: 0 0 0 1px #ebebeb;
  -webkit-box-shadow: 0 0 0 1px #ebebeb;
  box-shadow: 0 0 0 1px #ebebeb;
}
@media (max-width: 768px) {
  .version-highlights {
    display: block;
  }
  .version-highlights li {
    display: block;
  }
  .version-highlights li .screenshot,
  .version-highlights li .notes {
    display: block;
  }
  .version-highlights li .screenshot {
    text-align: center;
    width: 100%;
  }
  .version-highlights li .screenshot img {
    display: inline-block;
  }
  .version-highlights li .notes {
    text-align: center;
    margin-bottom: 30px;
  }
}
.input-spinner {
  position: relative;
}
.input-spinner:before,
.input-spinner:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.input-spinner:after {
  clear: both;
}
.input-spinner input,
.input-spinner button {
  float: left;
}
.input-spinner input {
  margin: 0;
  border-left: 0;
  border-right: 0;
  text-align: center;
  -webkit-border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0;
  -moz-background-clip: padding;
  border-radius: 0;
  background-clip: padding-box;
  width: auto;
}
.input-spinner input.size-1 {
  width: 60px;
}
.input-spinner input.size-2 {
  width: 100px;
}
.input-spinner input.size-3 {
  width: 120px;
}
.input-spinner input.size-4 {
  width: 160px;
}
.input-spinner button {
  -webkit-border-radius: 3px 0 0 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px;
  background-clip: padding-box;
}
.input-spinner button + input + button {
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}
.panel > .panel-body.with-table {
  position: relative;
  padding: 0;
  margin: -1px;
  border: 0;
}
.panel > .panel-body.with-table > table {
  margin: 0;
}
.disabled-text {
  -webkit-opacity: 0.6;
  -moz-opacity: 0.6;
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.page-body.gray .page-container .main-content,
.page-body.gray .page-container {
  background: #f1f1f1;
}
.tile-stats.stat-tile {
  padding: 0px;
  height: 155px;
  border: none !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
}
.tile-stats.tile-neon-red {
  background: #ff4e50;
}
.tile-stats.stat-tile h3 {
  padding: 20px 20px 0px 20px;
}
.tile-stats.stat-tile p {
  padding: 0px 20px 20px 20px;
  margin-bottom: 20px;
}
.pie-chart {
  position: absolute;
  right: 20px;
  top: 35px;
}
.tile-group-2 {
  background: #FFF !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
}
.tile-group-2 .tile-white {
  background: #FFF !important;
  border: none !important;
  color: #303641 !important;
}
.tile-group-2 .tile-entry {
  background: none !important;
  border: none !important;
  color: #303641 !important;
}
.tile-group-2 .tile-white .tile-entry h3 {
  color: #2b303a;
}
.tile-group-2 .tile-white .tile-entry span {
  color: rgba(0, 0, 0, 0.4);
}
.country-list {
  width: 90%;
  padding: 50px 0 20px 0;
  padding-left: 30px;
}
.country-list li {
  border-top: 1px solid #EEE;
  padding: 12px 0;
  list-style: none;
}
.tile-group-2 .tile-right .map {
  margin: 0px !important;
}
.panel-table {
  -moz-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  border: 0px !important;
}
.panel-table .panel-body {
  background: #FAFAFA;
}
.panel-table .panel-heading > .panel-title {
  padding: 30px 25px;
}
.panel-table .panel-heading > .panel-title span {
  color: #888888;
  font-size: 12px;
}
.panel-table th {
  font-weight: bold;
}
.calendar-widget td,
.calendar-widget th {
  text-align: center;
}
.calendar-widget th {
  padding: 12px 0;
}
@-moz-document url-prefix() {
  .page-container .tile-group .tile-left .map,
  .page-container .tile-group .tile-right .map {
    left: 40%;
    right: 16px;
  }
  .page-container .tile-stats .icon {
    bottom: 35px;
  }
}
.form-control + .description {
  display: block;
  margin-top: 5px;
  font-size: 11px;
}
.search-form-full {
  position: relative;
  margin-top: -2px;
}
.search-form-full .form-group {
  position: relative;
}
.search-form-full .form-group i {
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 14px;
  margin-top: -9px;
  color: rgba(148, 148, 148, 0.8);
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.search-form-full .form-group .form-control {
  margin-top: 12px;
  background: #f5f5f6;
  height: 38px;
  -moz-box-shadow: inset 0 1px 0 rgba(0,0,0,.015);
  -webkit-box-shadow: inset 0 1px 0 rgba(0,0,0,.015);
  box-shadow: inset 0 1px 0 rgba(0,0,0,.015);
}
.search-form-full .form-group .form-control:focus + i {
  color: #737881;
}
@media screen and (max-width: 480px) {
  .search-form-full {
    margin-top: 30px;
  }
}
.page-container .aciTree.aciBorder {
  border: 1px solid #ebebeb;
  min-height: 50px;
}
.neon-loading-bar {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: rgba(48, 54, 65, 0.3);
  height: 5px;
  z-index: 10000;
  top: 0px;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.neon-loading-bar span {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0%;
  background: #ec5956;
}
.neon-loading-bar.progress-is-hidden {
  top: -5px;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  filter: alpha(opacity=50);
  visibility: hidden;
}
.tabs-vertical-env {
  margin-bottom: 20px;
}
.tabs-vertical-env:before,
.tabs-vertical-env:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.tabs-vertical-env:after {
  clear: both;
}
.tabs-vertical-env .tabs-vertical {
  margin-bottom: 0;
}
.tabs-vertical-env .tabs-vertical + .tab-content {
  margin-bottom: 0;
}
.tabs-vertical {
  float: left;
  background: #f5f5f6;
  width: 20%;
  border: 1px solid #ebebeb;
  margin-bottom: 20px;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
}
.tabs-vertical > li {
  border-bottom: 1px solid #ebebeb;
}
.tabs-vertical > li:first-child > a {
  -webkit-border-radius: 3px 3px 0 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 3px 0 0;
  -moz-background-clip: padding;
  border-radius: 3px 3px 0 0;
  background-clip: padding-box;
}
.tabs-vertical > li:last-child {
  border-bottom: 0;
}
.tabs-vertical > li:last-child > a {
  -webkit-border-radius: 0 0 3px 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 0 3px 3px;
  -moz-background-clip: padding;
  border-radius: 0 0 3px 3px;
  background-clip: padding-box;
}
.tabs-vertical > li:hover > a {
  background-color: rgba(255, 255, 255, 0.3);
  color: #586376;
}
.tabs-vertical > li.active > a {
  display: block;
  position: relative;
  background-color: #ffffff;
  color: #373e4a;
}
.tabs-vertical > li.active > a:before {
  content: '';
  display: block;
  position: absolute;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 20px 0 20px 13px;
  border-color: transparent transparent transparent #ebebeb;
  top: 0;
  right: -1px;
  margin-right: -12px;
  margin-top: -1px;
}
.tabs-vertical > li.active > a:after {
  content: '';
  display: block;
  position: absolute;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 18px 0 18px 12px;
  border-color: transparent transparent transparent #fff;
  top: 2px;
  right: 0;
  margin-right: -12px;
  margin-top: -1px;
}
.tabs-vertical + .tab-content {
  float: right;
  width: 80%;
  padding-left: 25px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.tabs-vertical.right-aligned {
  float: right;
}
.tabs-vertical.right-aligned > li.active > a:before,
.tabs-vertical.right-aligned > li.active > a:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  right: auto;
}
.tabs-vertical.right-aligned > li.active > a:before {
  left: -13px;
}
.tabs-vertical.right-aligned > li.active > a:after {
  left: -12px;
}
.tabs-vertical.right-aligned + .tab-content {
  padding-right: 25px;
  padding-left: 0;
}
blockquote.blockquote-default {
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-left-width: 5px;
  padding: 15px;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
}
blockquote.blockquote-blue,
blockquote.blockquote-info {
  background: #c5e8f7;
  border: 1px solid #b6d6e4;
  border-left-width: 5px;
  padding: 15px;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
  color: #0c3c50;
}
blockquote.blockquote-blue p,
blockquote.blockquote-info p,
blockquote.blockquote-blue small,
blockquote.blockquote-info small {
  color: #85a6b5;
}
blockquote.blockquote-blue p strong,
blockquote.blockquote-info p strong,
blockquote.blockquote-blue small strong,
blockquote.blockquote-info small strong {
  color: #0c3c50;
}
blockquote.blockquote-green,
blockquote.blockquote-success {
  background: #bdedbc;
  border: 1px solid #aedaad;
  border-left-width: 5px;
  padding: 15px;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
  color: #135012;
}
blockquote.blockquote-green p,
blockquote.blockquote-success p,
blockquote.blockquote-green small,
blockquote.blockquote-success small {
  color: #7baa7a;
}
blockquote.blockquote-green p strong,
blockquote.blockquote-success p strong,
blockquote.blockquote-green small strong,
blockquote.blockquote-success small strong {
  color: #135012;
}
blockquote.blockquote-gold,
blockquote.blockquote-warning {
  background: #ffefa4;
  border: 1px solid #ebdc97;
  border-left-width: 5px;
  padding: 15px;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
  color: #584b0d;
}
blockquote.blockquote-gold p,
blockquote.blockquote-warning p,
blockquote.blockquote-gold small,
blockquote.blockquote-warning small {
  color: #b7a96a;
}
blockquote.blockquote-gold p strong,
blockquote.blockquote-warning p strong,
blockquote.blockquote-gold small strong,
blockquote.blockquote-warning small strong {
  color: #584b0d;
}
blockquote.blockquote-red,
blockquote.blockquote-danger {
  background: #ffc9c9;
  border: 1px solid #ebb9b9;
  border-left-width: 5px;
  padding: 15px;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
  color: #4e1c1c;
}
blockquote.blockquote-red p,
blockquote.blockquote-danger p,
blockquote.blockquote-red small,
blockquote.blockquote-danger small {
  color: #b48585;
}
blockquote.blockquote-red p strong,
blockquote.blockquote-danger p strong,
blockquote.blockquote-red small strong,
blockquote.blockquote-danger small strong {
  color: #4e1c1c;
}
.dataTables_wrapper:before,
.dataTables_wrapper:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.dataTables_wrapper:after {
  clear: both;
}
.dataTables_wrapper table {
  margin-bottom: 0;
}
.dataTables_wrapper table thead tr th {
  font-weight: bold;
  outline: none;
  cursor: default;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.dataTables_wrapper table thead tr th.sorting_asc:before,
.dataTables_wrapper table thead tr th.sorting_desc:before {
  display: block;
  float: right;
  color: #303641;
  font-family: 'Entypo';
  content: '\e876';
}
.dataTables_wrapper table thead tr th.sorting_desc:before {
  content: '\e873';
}
.dataTables_wrapper table thead tr th:active {
  outline: none;
}
.dataTables_wrapper table thead tr th.sorting_asc,
.dataTables_wrapper table thead tr th.sorting_desc {
  color: #303641;
}
.dataTables_wrapper table tfoot tr th {
  font-weight: bold;
}
.dataTables_wrapper table + .row {
  margin-bottom: 20px;
}
.dataTables_wrapper > table:first-child {
  -webkit-border-radius: 3px 3px 0 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 3px 0 0;
  -moz-background-clip: padding;
  border-radius: 3px 3px 0 0;
  background-clip: padding-box;
}
.dataTables_wrapper a {
  cursor: pointer;
  outline: none;
}
.dataTables_wrapper .col-left {
  padding-right: 0;
}
.dataTables_wrapper .col-right {
  padding-left: 0;
}
.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter {
  background: #fff;
  border: 1px solid #ebebeb;
  border-bottom: 0;
  padding: 15px 12px;
  height: 58px;
}
.dataTables_wrapper .dataTables_length:before,
.dataTables_wrapper .dataTables_filter:before,
.dataTables_wrapper .dataTables_length:after,
.dataTables_wrapper .dataTables_filter:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.dataTables_wrapper .dataTables_length:after,
.dataTables_wrapper .dataTables_filter:after {
  clear: both;
}
.dataTables_wrapper .dataTables_length {
  border-right: 0;
  -webkit-border-radius: 3px 0 0 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 0;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 0;
  background-clip: padding-box;
}
.dataTables_wrapper .dataTables_length > label {
  white-space: nowrap;
  margin-top: -7px;
}
.dataTables_wrapper .dataTables_length > label .select2-container {
  display: inline-block;
  width: auto !important;
  min-width: 87px;
}
.dataTables_wrapper .dataTables_filter {
  border-left: 0;
  padding-top: 13px;
  -webkit-border-radius: 0 3px 0 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 0 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 0 0;
  background-clip: padding-box;
}
.dataTables_wrapper .dataTables_filter > label {
  float: right;
  display: block;
  white-space: nowrap;
}
.dataTables_wrapper .dataTables_filter > label input {
  display: block;
  width: 100%;
  height: 31px;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -moz-transition: border-color ease-in-out .15s, -moz-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  display: inline-block;
  width: 150px;
  margin-left: 5px;
}
.dataTables_wrapper .dataTables_filter > label input:focus {
  border-color: #c8cdd7;
  outline: 0;
  -moz-box-shadow:  0 2px 1px rgba(203, 208, 217, 0.08);
  -webkit-box-shadow:  0 2px 1px rgba(203, 208, 217, 0.08);
  box-shadow:  0 2px 1px rgba(203, 208, 217, 0.08);
}
.dataTables_wrapper .dataTables_filter > label input::-webkit-input-placeholder {
  color: #aaaaaa;
}
.dataTables_wrapper .dataTables_filter > label input:-moz-placeholder {
  color: #aaaaaa;
}
.dataTables_wrapper .dataTables_filter > label input::-moz-placeholder {
  color: #aaaaaa;
}
.dataTables_wrapper .dataTables_filter > label input:-ms-input-placeholder {
  color: #aaaaaa;
}
.dataTables_wrapper .dataTables_filter > label input:-moz-placeholder {
  color: #aaaaaa;
}
.dataTables_wrapper .dataTables_filter > label input::-moz-placeholder {
  color: #aaaaaa;
}
.dataTables_wrapper .dataTables_filter > label input:-ms-input-placeholder {
  color: #aaaaaa;
}
.dataTables_wrapper .dataTables_filter > label input::-webkit-input-placeholder {
  color: #aaaaaa;
}
.dataTables_wrapper .dataTables_filter > label input[disabled],
.dataTables_wrapper .dataTables_filter > label input[readonly],
fieldset[disabled] .dataTables_wrapper .dataTables_filter > label input {
  cursor: not-allowed;
  background-color: #eeeeee;
}
textarea.dataTables_wrapper .dataTables_filter > label input {
  height: auto;
}
.dataTables_wrapper .select2-container {
  display: inline-block !important;
  margin-left: 5px;
  margin-right: 5px;
}
.dataTables_wrapper .select2-container .select2-choice {
  height: 25px;
  line-height: 24px;
}
.dataTables_wrapper .select2-container .select2-choice .select2-chosen {
  padding-right: 15px;
}
.dataTables_wrapper .select2-container .select2-choice .select2-arrow {
  width: 25px;
}
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_paginate {
  padding: 10px 12px;
  border: 1px solid #ebebeb;
  border-top: 0;
  background: #fafafa;
  height: 47px;
}
.dataTables_wrapper .dataTables_info:before,
.dataTables_wrapper .dataTables_paginate:before,
.dataTables_wrapper .dataTables_info:after,
.dataTables_wrapper .dataTables_paginate:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.dataTables_wrapper .dataTables_info:after,
.dataTables_wrapper .dataTables_paginate:after {
  clear: both;
}
.dataTables_wrapper .dataTables_info.dataTables_info,
.dataTables_wrapper .dataTables_paginate.dataTables_info {
  border-right: 0;
  -webkit-border-radius: 0 0 0 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 0 0 3px;
  -moz-background-clip: padding;
  border-radius: 0 0 0 3px;
  background-clip: padding-box;
  line-height: 30px;
}
.dataTables_wrapper .dataTables_info.dataTables_paginate,
.dataTables_wrapper .dataTables_paginate.dataTables_paginate {
  border-left: 0;
  -webkit-border-radius: 0 0 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 0 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 0 3px 0;
  background-clip: padding-box;
  text-align: right;
}
.dataTables_wrapper .dataTables_info.dataTables_paginate .pagination,
.dataTables_wrapper .dataTables_paginate.dataTables_paginate .pagination {
  margin: 0;
}
.dataTables_wrapper .export-data {
  position: absolute;
  right: 250px;
  top: 13px;
}
.page-body .datatable.table tbody td,
.page-body .datatable.table tbody th {
  vertical-align: middle;
}
table .replace-inputs input {
  display: block;
  width: 100%;
  height: 31px;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -moz-transition: border-color ease-in-out .15s, -moz-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
table .replace-inputs input:focus {
  border-color: #c8cdd7;
  outline: 0;
  -moz-box-shadow:  0 2px 1px rgba(203, 208, 217, 0.08);
  -webkit-box-shadow:  0 2px 1px rgba(203, 208, 217, 0.08);
  box-shadow:  0 2px 1px rgba(203, 208, 217, 0.08);
}
table .replace-inputs input::-webkit-input-placeholder {
  color: #aaaaaa;
}
table .replace-inputs input:-moz-placeholder {
  color: #aaaaaa;
}
table .replace-inputs input::-moz-placeholder {
  color: #aaaaaa;
}
table .replace-inputs input:-ms-input-placeholder {
  color: #aaaaaa;
}
table .replace-inputs input:-moz-placeholder {
  color: #aaaaaa;
}
table .replace-inputs input::-moz-placeholder {
  color: #aaaaaa;
}
table .replace-inputs input:-ms-input-placeholder {
  color: #aaaaaa;
}
table .replace-inputs input::-webkit-input-placeholder {
  color: #aaaaaa;
}
table .replace-inputs input[disabled],
table .replace-inputs input[readonly],
fieldset[disabled] table .replace-inputs input {
  cursor: not-allowed;
  background-color: #eeeeee;
}
textareatable .replace-inputs input {
  height: auto;
}
.dataTables_wrapper .dataTable {
  width: 100% !important;
}
/* Table Tools */
@media (min-width: 767px) {
  .chat-visible .main-content {
    padding-right: 300px;
  }
}
.chat-visible #chat {
  display: block;
}
.horizontal-menu #chat {
  top: 61px;
  border-top: 1px solid #454a54;
}
.horizontal-menu.chat-visible .main-content > .container {
  width: 100% !important;
}
.chat-notifications-badge {
  margin-left: 5px;
}
.chat-notifications-badge.is-hidden {
  display: none;
}
#chat {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 280px;
  background: #303641;
  z-index: 5;
  display: none;
}
#chat.fixed {
  position: fixed;
  min-height: 0 !important;
}
#chat .chat-inner {
  overflow: auto;
  height: 100%;
}
#chat .chat-header {
  font-size: 16px;
  color: #7f8186;
  padding: 30px 35px;
  line-height: 1;
  margin: 0;
  border-bottom: 1px solid #343a45;
  position: relative;
}
#chat .chat-header .badge {
  position: relative;
  top: -1px;
  margin-left: 5px;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
#chat .chat-header .badge.is-hidden {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform: scale(0, );
  -ms-transform: scale(0, );
  transform: scale(0, );
}
#chat .chat-header .chat-close {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 15px;
  top: 50%;
  right: 25px;
  margin-top: -7px;
  color: #bec0c2;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.transall #chat .chat-header .chat-close:hover {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}
#chat .chat-group {
  margin-top: 30px;
}
#chat .chat-group > strong,
#chat .chat-group > a {
  display: block;
  padding: 6px 35px;
}
#chat .chat-group > strong {
  text-transform: uppercase;
  color: #7f8186;
}
#chat .chat-group > a {
  position: relative;
  color: #bec0c2;
  -moz-transition: background 250ms ease-in-out;
  -o-transition: background 250ms ease-in-out;
  -webkit-transition: background 250ms ease-in-out;
  transition: background 250ms ease-in-out;
}
#chat .chat-group > a:hover,
#chat .chat-group > a.active {
  background-color: #343a45;
}
#chat .chat-group > a:before {
  content: '';
  display: block;
  position: absolute;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 4px 0 4px 4px;
  border-color: transparent transparent transparent #2b303a;
  left: 0;
  top: 50%;
  margin-top: -4px;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
#chat .chat-group > a.active:before {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}
#chat .chat-group > a .badge {
  font-size: 9px;
  margin-left: 5px;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
#chat .chat-group > a .badge.is-hidden {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform: scale(0, );
  -ms-transform: scale(0, );
  transform: scale(0, );
}
#chat .chat-group > a em {
  font-style: normal;
}
#chat .user-status {
  display: inline-block;
  background: #575d67;
  margin-right: 5px;
  width: 8px;
  height: 8px;
  -webkit-border-radius: 8px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 8px;
  -moz-background-clip: padding;
  border-radius: 8px;
  background-clip: padding-box;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
#chat .user-status.is-online {
  background-color: #06b53c;
}
#chat .user-status.is-offline {
  background-color: #575d67;
}
#chat .user-status.is-idle {
  background-color: #f7d227;
}
#chat .user-status.is-busy {
  background-color: #ee4749;
}
#chat .chat-conversation {
  position: absolute;
  right: 280px;
  width: 340px;
  background: #2b303a;
  -webkit-border-radius: 3px 0 0 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 0 3px;
  -moz-background-clip: padding;
  border-radius: 3px 0 0 3px;
  background-clip: padding-box;
  display: none;
  opacity: 0;
}
#chat .chat-conversation .conversation-header {
  padding: 20px 24px;
  font-size: 14px;
  color: #fff;
  border-bottom: 1px solid #343a45;
}
#chat .chat-conversation .conversation-header small {
  color: rgba(190, 192, 194, 0.6);
  font-size: 12px;
  padding-left: 8px;
}
#chat .chat-conversation .conversation-header .conversation-close {
  float: right;
  color: #7f8186;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
  position: relative;
  top: 3px;
}
#chat .chat-conversation .conversation-body {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  height: 250px;
}
#chat .chat-conversation .conversation-body::-webkit-scrollbar {
  width: 5px;
}
#chat .chat-conversation .conversation-body::-webkit-scrollbar-track {
  width: 5px;
  background-color: #2b303a;
}
#chat .chat-conversation .conversation-body::-webkit-scrollbar-thumb {
  background-color: rgba(52, 58, 69, 0.8);
}
#chat .chat-conversation .conversation-body > li {
  padding: 20px 24px;
  padding-top: 15px;
  padding-bottom: 15px;
}
#chat .chat-conversation .conversation-body > li:before,
#chat .chat-conversation .conversation-body > li:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
#chat .chat-conversation .conversation-body > li:after {
  clear: both;
}
#chat .chat-conversation .conversation-body > li.odd,
#chat .chat-conversation .conversation-body > li.even,
#chat .chat-conversation .conversation-body > li.opponent {
  background: #343a45;
}
#chat .chat-conversation .conversation-body > li.unread {
  background: #3d4451;
}
#chat .chat-conversation .conversation-body > li .user {
  font-weight: bold;
  color: #fff;
}
#chat .chat-conversation .conversation-body > li .time {
  float: right;
  font-style: italic;
  color: rgba(190, 192, 194, 0.8);
  font-size: 11px;
}
#chat .chat-conversation .chat-textarea {
  padding: 20px 24px;
  position: relative;
}
#chat .chat-conversation .chat-textarea:after {
  content: '\e83c';
  font-family: 'Entypo';
  color: #bec0c2;
  right: 35px;
  top: 25px;
  font-size: 15px;
  position: absolute;
}
#chat .chat-conversation .chat-textarea textarea {
  background: #343a45;
  border: 1px solid #343a45;
  color: #fff;
  max-height: 100px !important;
  padding-right: 35px;
  height: 32px;
}
#chat .chat-conversation .chat-textarea textarea::-webkit-input-placeholder {
  color: rgba(190, 192, 194, 0.85);
}
#chat .chat-conversation .chat-textarea textarea:-moz-placeholder {
  color: rgba(190, 192, 194, 0.85);
}
#chat .chat-conversation .chat-textarea textarea::-moz-placeholder {
  color: rgba(190, 192, 194, 0.85);
}
#chat .chat-conversation .chat-textarea textarea:-ms-input-placeholder {
  color: rgba(190, 192, 194, 0.85);
}
#chat .chat-conversation .chat-textarea textarea:-moz-placeholder {
  color: rgba(190, 192, 194, 0.85);
}
#chat .chat-conversation .chat-textarea textarea::-moz-placeholder {
  color: rgba(190, 192, 194, 0.85);
}
#chat .chat-conversation .chat-textarea textarea:-ms-input-placeholder {
  color: rgba(190, 192, 194, 0.85);
}
#chat .chat-conversation .chat-textarea textarea::-webkit-input-placeholder {
  color: rgba(190, 192, 194, 0.85);
}
#chat .chat-conversation .chat-textarea textarea::-webkit-scrollbar {
  width: 5px;
}
#chat .chat-conversation .chat-textarea textarea::-webkit-scrollbar-track {
  width: 5px;
  background-color: #343a45;
}
#chat .chat-conversation .chat-textarea textarea::-webkit-scrollbar-thumb {
  background-color: #4a5262;
}
.chat-history {
  display: none;
}
/* Relatively-Small screen */
@media (max-width: 767px) {
  .chat-visible #chat,
  .chat-visible #chat.fixed {
    display: none;
  }
  .chat-visible.toggle-click #chat,
  .chat-visible.toggle-click #chat.fixed {
    display: block;
  }
  #chat,
  #chat.fixed {
    position: fixed;
    width: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 10px;
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box;
  }
  #chat .chat-conversation,
  #chat.fixed .chat-conversation {
    position: fixed;
    width: auto;
    left: 0;
    right: 0;
    top: 0 !important;
    bottom: 0;
    margin: 10px;
    overflow: auto;
    -webkit-border-radius: 3px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 3px;
    -moz-background-clip: padding;
    border-radius: 3px;
    background-clip: padding-box;
  }
  #chat .chat-conversation .conversation-body,
  #chat.fixed .chat-conversation .conversation-body {
    height: auto;
    max-height: 450px;
  }
  #chat .chat-conversation::-webkit-scrollbar,
  #chat.fixed .chat-conversation::-webkit-scrollbar {
    width: 5px;
  }
  #chat .chat-conversation::-webkit-scrollbar-track,
  #chat.fixed .chat-conversation::-webkit-scrollbar-track {
    width: 5px;
    background-color: #343a45;
  }
  #chat .chat-conversation::-webkit-scrollbar-thumb,
  #chat.fixed .chat-conversation::-webkit-scrollbar-thumb {
    background-color: #4a5262;
  }
}
.tile-stats {
  position: relative;
  display: block;
  background: #303641;
  padding: 20px;
  margin-bottom: 10px;
  overflow: hidden;
  -webkit-border-radius: 5px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 5px;
  -moz-background-clip: padding;
  border-radius: 5px;
  background-clip: padding-box;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.tile-stats:hover {
  background: #252a32;
}
.tile-stats .icon {
  color: rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 1;
}
.tile-stats .icon i {
  font-size: 100px;
  line-height: 0;
  margin: 0;
  padding: 0;
  vertical-align: bottom;
}
.tile-stats .icon i:before {
  margin: 0;
  padding: 0;
  line-height: 0;
}
.tile-stats .num,
.tile-stats h3,
.tile-stats p {
  position: relative;
  color: #ffffff;
  z-index: 5;
  margin: 0;
  padding: 0;
}
.tile-stats .num {
  font-size: 38px;
  font-weight: bold;
}
.tile-stats h3 {
  font-size: 18px;
  margin-top: 5px;
}
.tile-stats p {
  font-size: 11px;
  margin-top: 5px;
}
.tile-stats.tile-red {
  background: #f56954;
}
.tile-stats.tile-red:hover {
  background: #f4543c;
}
.tile-stats.tile-red .icon {
  color: rgba(0, 0, 0, 0.1);
}
.tile-stats.tile-red .num,
.tile-stats.tile-red h3,
.tile-stats.tile-red p {
  color: #ffffff;
}
.tile-stats.tile-green {
  background: #00a65a;
}
.tile-stats.tile-green:hover {
  background: #008d4c;
}
.tile-stats.tile-green .icon {
  color: rgba(0, 0, 0, 0.1);
}
.tile-stats.tile-green .num,
.tile-stats.tile-green h3,
.tile-stats.tile-green p {
  color: #ffffff;
}
.tile-stats.tile-blue {
  background: #0073b7;
}
.tile-stats.tile-blue:hover {
  background: #00639e;
}
.tile-stats.tile-blue .icon {
  color: rgba(0, 0, 0, 0.1);
}
.tile-stats.tile-blue .num,
.tile-stats.tile-blue h3,
.tile-stats.tile-blue p {
  color: #ffffff;
}
.tile-stats.tile-aqua {
  background: #00c0ef;
}
.tile-stats.tile-aqua:hover {
  background: #00acd6;
}
.tile-stats.tile-aqua .icon {
  color: rgba(0, 0, 0, 0.1);
}
.tile-stats.tile-aqua .num,
.tile-stats.tile-aqua h3,
.tile-stats.tile-aqua p {
  color: #ffffff;
}
.tile-stats.tile-cyan {
  background: #00b29e;
}
.tile-stats.tile-cyan:hover {
  background: #009987;
}
.tile-stats.tile-cyan .icon {
  color: rgba(0, 0, 0, 0.1);
}
.tile-stats.tile-cyan .num,
.tile-stats.tile-cyan h3,
.tile-stats.tile-cyan p {
  color: #ffffff;
}
.tile-stats.tile-purple {
  background: #ba79cb;
}
.tile-stats.tile-purple:hover {
  background: #b167c4;
}
.tile-stats.tile-purple .icon {
  color: rgba(0, 0, 0, 0.1);
}
.tile-stats.tile-purple .num,
.tile-stats.tile-purple h3,
.tile-stats.tile-purple p {
  color: #ffffff;
}
.tile-stats.tile-pink {
  background: #ec3b83;
}
.tile-stats.tile-pink:hover {
  background: #ea2474;
}
.tile-stats.tile-pink .icon {
  color: rgba(0, 0, 0, 0.1);
}
.tile-stats.tile-pink .num,
.tile-stats.tile-pink h3,
.tile-stats.tile-pink p {
  color: #ffffff;
}
.tile-stats.tile-orange {
  background: #ffa812;
}
.tile-stats.tile-orange:hover {
  background: #f89d00;
}
.tile-stats.tile-orange .icon {
  color: rgba(0, 0, 0, 0.1);
}
.tile-stats.tile-orange .num,
.tile-stats.tile-orange h3,
.tile-stats.tile-orange p {
  color: #ffffff;
}
.tile-stats.tile-brown {
  background: #6c541e;
}
.tile-stats.tile-brown:hover {
  background: #584418;
}
.tile-stats.tile-brown .icon {
  color: rgba(0, 0, 0, 0.1);
}
.tile-stats.tile-brown .num,
.tile-stats.tile-brown h3,
.tile-stats.tile-brown p {
  color: #ffffff;
}
.tile-stats.tile-plum {
  background: #701c1c;
}
.tile-stats.tile-plum:hover {
  background: #5c1717;
}
.tile-stats.tile-plum .icon {
  color: rgba(0, 0, 0, 0.1);
}
.tile-stats.tile-plum .num,
.tile-stats.tile-plum h3,
.tile-stats.tile-plum p {
  color: #ffffff;
}
.tile-stats.tile-gray {
  background: #f5f5f5;
}
.tile-stats.tile-gray:hover {
  background: #e8e8e8;
}
.tile-stats.tile-gray .icon {
  color: rgba(0, 0, 0, 0.1);
}
.tile-stats.tile-gray .num,
.tile-stats.tile-gray h3,
.tile-stats.tile-gray p {
  color: #8f8f8f;
}
.tile-stats.tile-white {
  background: #ffffff;
  border: 1px solid #ebebeb;
}
.tile-stats.tile-white:hover {
  background: #f2f2f2;
}
.tile-stats.tile-white .icon {
  color: #f2f2f2;
}
.tile-stats.tile-white .num,
.tile-stats.tile-white h3,
.tile-stats.tile-white p {
  color: #303641;
}
.tile-stats.tile-white:hover {
  background-color: #fafafa;
}
.tile-stats.tile-white-red {
  background: #ffffff;
  border: 1px solid #ebebeb;
}
.tile-stats.tile-white-red:hover {
  background: #f2f2f2;
}
.tile-stats.tile-white-red .icon {
  color: #f2f2f2;
}
.tile-stats.tile-white-red .num,
.tile-stats.tile-white-red h3,
.tile-stats.tile-white-red p {
  color: #f56954;
}
.tile-stats.tile-white-red:hover {
  background-color: #fafafa;
}
.tile-stats.tile-white-green {
  background: #ffffff;
  border: 1px solid #ebebeb;
}
.tile-stats.tile-white-green:hover {
  background: #f2f2f2;
}
.tile-stats.tile-white-green .icon {
  color: #f2f2f2;
}
.tile-stats.tile-white-green .num,
.tile-stats.tile-white-green h3,
.tile-stats.tile-white-green p {
  color: #00a65a;
}
.tile-stats.tile-white-green:hover {
  background-color: #fafafa;
}
.tile-stats.tile-white-blue {
  background: #ffffff;
  border: 1px solid #ebebeb;
}
.tile-stats.tile-white-blue:hover {
  background: #f2f2f2;
}
.tile-stats.tile-white-blue .icon {
  color: #f2f2f2;
}
.tile-stats.tile-white-blue .num,
.tile-stats.tile-white-blue h3,
.tile-stats.tile-white-blue p {
  color: #0073b7;
}
.tile-stats.tile-white-blue:hover {
  background-color: #fafafa;
}
.tile-stats.tile-white-aqua {
  background: #ffffff;
  border: 1px solid #ebebeb;
}
.tile-stats.tile-white-aqua:hover {
  background: #f2f2f2;
}
.tile-stats.tile-white-aqua .icon {
  color: #f2f2f2;
}
.tile-stats.tile-white-aqua .num,
.tile-stats.tile-white-aqua h3,
.tile-stats.tile-white-aqua p {
  color: #00c0ef;
}
.tile-stats.tile-white-aqua:hover {
  background-color: #fafafa;
}
.tile-stats.tile-white-cyan {
  background: #ffffff;
  border: 1px solid #ebebeb;
}
.tile-stats.tile-white-cyan:hover {
  background: #f2f2f2;
}
.tile-stats.tile-white-cyan .icon {
  color: #f2f2f2;
}
.tile-stats.tile-white-cyan .num,
.tile-stats.tile-white-cyan h3,
.tile-stats.tile-white-cyan p {
  color: #00b29e;
}
.tile-stats.tile-white-cyan:hover {
  background-color: #fafafa;
}
.tile-stats.tile-white-purple {
  background: #ffffff;
  border: 1px solid #ebebeb;
}
.tile-stats.tile-white-purple:hover {
  background: #f2f2f2;
}
.tile-stats.tile-white-purple .icon {
  color: #f2f2f2;
}
.tile-stats.tile-white-purple .num,
.tile-stats.tile-white-purple h3,
.tile-stats.tile-white-purple p {
  color: #ba79cb;
}
.tile-stats.tile-white-purple:hover {
  background-color: #fafafa;
}
.tile-stats.tile-white-pink {
  background: #ffffff;
  border: 1px solid #ebebeb;
}
.tile-stats.tile-white-pink:hover {
  background: #f2f2f2;
}
.tile-stats.tile-white-pink .icon {
  color: #f2f2f2;
}
.tile-stats.tile-white-pink .num,
.tile-stats.tile-white-pink h3,
.tile-stats.tile-white-pink p {
  color: #ec3b83;
}
.tile-stats.tile-white-pink:hover {
  background-color: #fafafa;
}
.tile-stats.tile-white-orange {
  background: #ffffff;
  border: 1px solid #ebebeb;
}
.tile-stats.tile-white-orange:hover {
  background: #f2f2f2;
}
.tile-stats.tile-white-orange .icon {
  color: #f2f2f2;
}
.tile-stats.tile-white-orange .num,
.tile-stats.tile-white-orange h3,
.tile-stats.tile-white-orange p {
  color: #ffa812;
}
.tile-stats.tile-white-orange:hover {
  background-color: #fafafa;
}
.tile-stats.tile-white-brown {
  background: #ffffff;
  border: 1px solid #ebebeb;
}
.tile-stats.tile-white-brown:hover {
  background: #f2f2f2;
}
.tile-stats.tile-white-brown .icon {
  color: #f2f2f2;
}
.tile-stats.tile-white-brown .num,
.tile-stats.tile-white-brown h3,
.tile-stats.tile-white-brown p {
  color: #6c541e;
}
.tile-stats.tile-white-brown:hover {
  background-color: #fafafa;
}
.tile-stats.tile-white-plum {
  background: #ffffff;
  border: 1px solid #ebebeb;
}
.tile-stats.tile-white-plum:hover {
  background: #f2f2f2;
}
.tile-stats.tile-white-plum .icon {
  color: #f2f2f2;
}
.tile-stats.tile-white-plum .num,
.tile-stats.tile-white-plum h3,
.tile-stats.tile-white-plum p {
  color: #701c1c;
}
.tile-stats.tile-white-plum:hover {
  background-color: #fafafa;
}
.tile-stats.tile-white-gray {
  background: #ffffff;
  border: 1px solid #ebebeb;
}
.tile-stats.tile-white-gray:hover {
  background: #f2f2f2;
}
.tile-stats.tile-white-gray .icon {
  color: #f2f2f2;
}
.tile-stats.tile-white-gray .num,
.tile-stats.tile-white-gray h3,
.tile-stats.tile-white-gray p {
  color: #8f8f8f;
}
.tile-stats.tile-white-gray:hover {
  background-color: #fafafa;
}
.tile-title {
  position: relative;
  display: block;
  background: #303641;
  margin-bottom: 10px;
  -webkit-border-radius: 5px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 5px;
  -moz-background-clip: padding;
  border-radius: 5px;
  background-clip: padding-box;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.tile-title:hover {
  background-color: #252a32;
}
.tile-title .icon {
  text-align: center;
  padding: 20px;
}
.tile-title .icon i {
  font-size: 60px;
  line-height: 1;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
.tile-title .icon i:before {
  margin: 0;
  padding: 0;
  line-height: 1;
}
.tile-title .icon i,
.tile-title h3,
.tile-title p {
  color: #ffffff;
}
.tile-title .title {
  background: #252a32;
  text-align: center;
}
.tile-title .title h3,
.tile-title .title p {
  margin: 0;
  padding: 0 20px;
}
.tile-title .title h3 {
  padding-top: 20px;
  font-size: 16px;
  font-weight: bold;
}
.tile-title .title p {
  padding-bottom: 20px;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.85);
}
.tile-title.tile-red {
  background: #f56954;
}
.tile-title.tile-red:hover {
  background-color: #f4543c;
}
.tile-title.tile-red .icon i,
.tile-title.tile-red h3,
.tile-title.tile-red p {
  color: #ffffff;
}
.tile-title.tile-red .icon i {
  color: #ffffff;
}
.tile-title.tile-red .title {
  background: rgba(0, 0, 0, 0.1);
}
.tile-title.tile-red .title p {
  color: rgba(255, 255, 255, 0.85);
}
.tile-title.tile-green {
  background: #00a65a;
}
.tile-title.tile-green:hover {
  background-color: #008d4c;
}
.tile-title.tile-green .icon i,
.tile-title.tile-green h3,
.tile-title.tile-green p {
  color: #ffffff;
}
.tile-title.tile-green .icon i {
  color: #ffffff;
}
.tile-title.tile-green .title {
  background: rgba(0, 0, 0, 0.1);
}
.tile-title.tile-green .title p {
  color: rgba(255, 255, 255, 0.85);
}
.tile-title.tile-blue {
  background: #0073b7;
}
.tile-title.tile-blue:hover {
  background-color: #00639e;
}
.tile-title.tile-blue .icon i,
.tile-title.tile-blue h3,
.tile-title.tile-blue p {
  color: #ffffff;
}
.tile-title.tile-blue .icon i {
  color: #ffffff;
}
.tile-title.tile-blue .title {
  background: rgba(0, 0, 0, 0.1);
}
.tile-title.tile-blue .title p {
  color: rgba(255, 255, 255, 0.85);
}
.tile-title.tile-aqua {
  background: #00c0ef;
}
.tile-title.tile-aqua:hover {
  background-color: #00acd6;
}
.tile-title.tile-aqua .icon i,
.tile-title.tile-aqua h3,
.tile-title.tile-aqua p {
  color: #ffffff;
}
.tile-title.tile-aqua .icon i {
  color: #ffffff;
}
.tile-title.tile-aqua .title {
  background: rgba(0, 0, 0, 0.1);
}
.tile-title.tile-aqua .title p {
  color: rgba(255, 255, 255, 0.85);
}
.tile-title.tile-cyan {
  background: #00b29e;
}
.tile-title.tile-cyan:hover {
  background-color: #009987;
}
.tile-title.tile-cyan .icon i,
.tile-title.tile-cyan h3,
.tile-title.tile-cyan p {
  color: #ffffff;
}
.tile-title.tile-cyan .icon i {
  color: #ffffff;
}
.tile-title.tile-cyan .title {
  background: rgba(0, 0, 0, 0.1);
}
.tile-title.tile-cyan .title p {
  color: rgba(255, 255, 255, 0.85);
}
.tile-title.tile-purple {
  background: #ba79cb;
}
.tile-title.tile-purple:hover {
  background-color: #b167c4;
}
.tile-title.tile-purple .icon i,
.tile-title.tile-purple h3,
.tile-title.tile-purple p {
  color: #ffffff;
}
.tile-title.tile-purple .icon i {
  color: #ffffff;
}
.tile-title.tile-purple .title {
  background: rgba(0, 0, 0, 0.1);
}
.tile-title.tile-purple .title p {
  color: rgba(255, 255, 255, 0.85);
}
.tile-title.tile-pink {
  background: #ec3b83;
}
.tile-title.tile-pink:hover {
  background-color: #ea2474;
}
.tile-title.tile-pink .icon i,
.tile-title.tile-pink h3,
.tile-title.tile-pink p {
  color: #ffffff;
}
.tile-title.tile-pink .icon i {
  color: #ffffff;
}
.tile-title.tile-pink .title {
  background: rgba(0, 0, 0, 0.1);
}
.tile-title.tile-pink .title p {
  color: rgba(255, 255, 255, 0.85);
}
.tile-title.tile-orange {
  background: #ffa812;
}
.tile-title.tile-orange:hover {
  background-color: #f89d00;
}
.tile-title.tile-orange .icon i,
.tile-title.tile-orange h3,
.tile-title.tile-orange p {
  color: #ffffff;
}
.tile-title.tile-orange .icon i {
  color: #ffffff;
}
.tile-title.tile-orange .title {
  background: rgba(0, 0, 0, 0.1);
}
.tile-title.tile-orange .title p {
  color: rgba(255, 255, 255, 0.85);
}
.tile-title.tile-brown {
  background: #6c541e;
}
.tile-title.tile-brown:hover {
  background-color: #584418;
}
.tile-title.tile-brown .icon i,
.tile-title.tile-brown h3,
.tile-title.tile-brown p {
  color: #ffffff;
}
.tile-title.tile-brown .icon i {
  color: #ffffff;
}
.tile-title.tile-brown .title {
  background: rgba(0, 0, 0, 0.1);
}
.tile-title.tile-brown .title p {
  color: rgba(255, 255, 255, 0.85);
}
.tile-title.tile-plum {
  background: #701c1c;
}
.tile-title.tile-plum:hover {
  background-color: #5c1717;
}
.tile-title.tile-plum .icon i,
.tile-title.tile-plum h3,
.tile-title.tile-plum p {
  color: #ffffff;
}
.tile-title.tile-plum .icon i {
  color: #ffffff;
}
.tile-title.tile-plum .title {
  background: rgba(0, 0, 0, 0.1);
}
.tile-title.tile-plum .title p {
  color: rgba(255, 255, 255, 0.85);
}
.tile-title.tile-gray {
  background: #f5f5f5;
}
.tile-title.tile-gray:hover {
  background-color: #e8e8e8;
}
.tile-title.tile-gray .icon i,
.tile-title.tile-gray h3,
.tile-title.tile-gray p {
  color: #8f8f8f;
}
.tile-title.tile-gray .icon i {
  color: #8f8f8f;
}
.tile-title.tile-gray .title {
  background: rgba(0, 0, 0, 0.1);
}
.tile-title.tile-gray .title p {
  color: rgba(143, 143, 143, 0.85);
}
.tile-block {
  background: #00a65b;
  -webkit-border-radius: 5px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 5px;
  -moz-background-clip: padding;
  border-radius: 5px;
  background-clip: padding-box;
  margin-bottom: 20px;
  background-color: #303641;
  color: #ffffff;
}
.tile-block:before,
.tile-block:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.tile-block:after {
  clear: both;
}
.tile-block .tile-header {
  color: #ffffff;
  font-size: 17px;
  padding: 20px;
}
.tile-block .tile-header a,
.tile-block .tile-header span {
  color: #ffffff;
}
.tile-block .tile-header span {
  display: block;
  margin-top: 4px;
  font-size: 11px;
}
.tile-block .tile-header i {
  float: right;
  font-size: 28px;
  position: relative;
  top: 4px;
}
.tile-block .tile-header i[class^="entypo-"] {
  top: 0;
}
.tile-block .tile-content {
  background: rgba(0, 0, 0, 0.03);
  padding: 20px;
}
.tile-block .tile-content .todo-list .neon-cb-replacement .cb-wrapper + label {
  top: -3px;
  margin-left: 8px;
}
.tile-block .tile-footer {
  background: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 20px;
  color: #ffffff;
  -webkit-border-radius: 0 0 5px 5px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 0 5px 5px;
  -moz-background-clip: padding;
  border-radius: 0 0 5px 5px;
  background-clip: padding-box;
}
.tile-block .tile-footer a {
  color: #ffffff;
  font-weight: bold;
}
.tile-block .form-control,
.tile-block .btn {
  background: rgba(0, 0, 0, 0.15);
  color: #ffffff;
  border-color: transparent;
}
.tile-block .form-control::-webkit-input-placeholder,
.tile-block .btn::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.tile-block .form-control:-moz-placeholder,
.tile-block .btn:-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.tile-block .form-control::-moz-placeholder,
.tile-block .btn::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.tile-block .form-control:-ms-input-placeholder,
.tile-block .btn:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.tile-block .form-control:-moz-placeholder,
.tile-block .btn:-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.tile-block .form-control::-moz-placeholder,
.tile-block .btn::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.tile-block .form-control:-ms-input-placeholder,
.tile-block .btn:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.tile-block .form-control::-webkit-input-placeholder,
.tile-block .btn::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.tile-block .form-control + .todo-list,
.tile-block .btn + .todo-list {
  margin-top: 20px;
}
.tile-block .form-control + .btn {
  margin-top: 10px;
}
.tile-block .tile-header a,
.tile-block .tile-header span,
.tile-block .tile-header i {
  color: #ffffff;
}
.tile-block .tile-content .todo-list .neon-cb-replacement .cb-wrapper .checked {
  background: #303641;
}
.tile-block .tile-footer {
  color: #ffffff;
}
.tile-block .tile-footer a {
  color: #ffffff;
}
.tile-block.tile-red {
  background-color: #f56954;
  color: #ffffff;
}
.tile-block.tile-red .tile-header a,
.tile-block.tile-red .tile-header span,
.tile-block.tile-red .tile-header i {
  color: #ffffff;
}
.tile-block.tile-red .tile-content .todo-list .neon-cb-replacement .cb-wrapper .checked {
  background: #f56954;
}
.tile-block.tile-red .tile-footer {
  color: #ffffff;
}
.tile-block.tile-red .tile-footer a {
  color: #ffffff;
}
.tile-block.tile-green {
  background-color: #00a65a;
  color: #ffffff;
}
.tile-block.tile-green .tile-header a,
.tile-block.tile-green .tile-header span,
.tile-block.tile-green .tile-header i {
  color: #ffffff;
}
.tile-block.tile-green .tile-content .todo-list .neon-cb-replacement .cb-wrapper .checked {
  background: #00a65a;
}
.tile-block.tile-green .tile-footer {
  color: #ffffff;
}
.tile-block.tile-green .tile-footer a {
  color: #ffffff;
}
.tile-block.tile-blue {
  background-color: #0073b7;
  color: #ffffff;
}
.tile-block.tile-blue .tile-header a,
.tile-block.tile-blue .tile-header span,
.tile-block.tile-blue .tile-header i {
  color: #ffffff;
}
.tile-block.tile-blue .tile-content .todo-list .neon-cb-replacement .cb-wrapper .checked {
  background: #0073b7;
}
.tile-block.tile-blue .tile-footer {
  color: #ffffff;
}
.tile-block.tile-blue .tile-footer a {
  color: #ffffff;
}
.tile-block.tile-aqua {
  background-color: #00c0ef;
  color: #ffffff;
}
.tile-block.tile-aqua .tile-header a,
.tile-block.tile-aqua .tile-header span,
.tile-block.tile-aqua .tile-header i {
  color: #ffffff;
}
.tile-block.tile-aqua .tile-content .todo-list .neon-cb-replacement .cb-wrapper .checked {
  background: #00c0ef;
}
.tile-block.tile-aqua .tile-footer {
  color: #ffffff;
}
.tile-block.tile-aqua .tile-footer a {
  color: #ffffff;
}
.tile-block.tile-cyan {
  background-color: #00b29e;
  color: #ffffff;
}
.tile-block.tile-cyan .tile-header a,
.tile-block.tile-cyan .tile-header span,
.tile-block.tile-cyan .tile-header i {
  color: #ffffff;
}
.tile-block.tile-cyan .tile-content .todo-list .neon-cb-replacement .cb-wrapper .checked {
  background: #00b29e;
}
.tile-block.tile-cyan .tile-footer {
  color: #ffffff;
}
.tile-block.tile-cyan .tile-footer a {
  color: #ffffff;
}
.tile-block.tile-purple {
  background-color: #ba79cb;
  color: #ffffff;
}
.tile-block.tile-purple .tile-header a,
.tile-block.tile-purple .tile-header span,
.tile-block.tile-purple .tile-header i {
  color: #ffffff;
}
.tile-block.tile-purple .tile-content .todo-list .neon-cb-replacement .cb-wrapper .checked {
  background: #ba79cb;
}
.tile-block.tile-purple .tile-footer {
  color: #ffffff;
}
.tile-block.tile-purple .tile-footer a {
  color: #ffffff;
}
.tile-block.tile-pink {
  background-color: #ec3b83;
  color: #ffffff;
}
.tile-block.tile-pink .tile-header a,
.tile-block.tile-pink .tile-header span,
.tile-block.tile-pink .tile-header i {
  color: #ffffff;
}
.tile-block.tile-pink .tile-content .todo-list .neon-cb-replacement .cb-wrapper .checked {
  background: #ec3b83;
}
.tile-block.tile-pink .tile-footer {
  color: #ffffff;
}
.tile-block.tile-pink .tile-footer a {
  color: #ffffff;
}
.tile-block.tile-orange {
  background-color: #ffa812;
  color: #ffffff;
}
.tile-block.tile-orange .tile-header a,
.tile-block.tile-orange .tile-header span,
.tile-block.tile-orange .tile-header i {
  color: #ffffff;
}
.tile-block.tile-orange .tile-content .todo-list .neon-cb-replacement .cb-wrapper .checked {
  background: #ffa812;
}
.tile-block.tile-orange .tile-footer {
  color: #ffffff;
}
.tile-block.tile-orange .tile-footer a {
  color: #ffffff;
}
.tile-block.tile-brown {
  background-color: #6c541e;
  color: #ffffff;
}
.tile-block.tile-brown .tile-header a,
.tile-block.tile-brown .tile-header span,
.tile-block.tile-brown .tile-header i {
  color: #ffffff;
}
.tile-block.tile-brown .tile-content .todo-list .neon-cb-replacement .cb-wrapper .checked {
  background: #6c541e;
}
.tile-block.tile-brown .tile-footer {
  color: #ffffff;
}
.tile-block.tile-brown .tile-footer a {
  color: #ffffff;
}
.tile-block.tile-plum {
  background-color: #701c1c;
  color: #ffffff;
}
.tile-block.tile-plum .tile-header a,
.tile-block.tile-plum .tile-header span,
.tile-block.tile-plum .tile-header i {
  color: #ffffff;
}
.tile-block.tile-plum .tile-content .todo-list .neon-cb-replacement .cb-wrapper .checked {
  background: #701c1c;
}
.tile-block.tile-plum .tile-footer {
  color: #ffffff;
}
.tile-block.tile-plum .tile-footer a {
  color: #ffffff;
}
.tile-block.tile-gray {
  background-color: #f5f5f5;
  color: #767676;
}
.tile-block.tile-gray .tile-header a,
.tile-block.tile-gray .tile-header span,
.tile-block.tile-gray .tile-header i {
  color: #767676;
}
.tile-block.tile-gray .tile-content .todo-list .neon-cb-replacement .cb-wrapper .checked {
  background: #f5f5f5;
}
.tile-block.tile-gray .tile-footer {
  color: #767676;
}
.tile-block.tile-gray .tile-footer a {
  color: #767676;
}
.tile-block.tile-gray .tile-content .todo-list .neon-cb-replacement .cb-wrapper .checked {
  background: #dcdcdc;
}
.todo-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.todo-list > li {
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
}
.todo-list > li:last-child {
  margin-bottom: 0;
}
.todo-list > li .neon-cb-replacement .cb-wrapper {
  background: #fff;
}
.todo-list > li .neon-cb-replacement .cb-wrapper + label {
  position: relative;
}
.todo-list > li .neon-cb-replacement .cb-wrapper + label:after {
  position: absolute;
  content: '';
  height: 2px;
  background: #fff;
  width: 0%;
  top: 50%;
  left: 0;
  margin: 0;
  margin-top: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.todo-list > li .neon-cb-replacement.checked .cb-wrapper + label:after {
  -webkit-opacity: 0.8;
  -moz-opacity: 0.8;
  opacity: 0.8;
  filter: alpha(opacity=80);
  width: 100%;
}
.todo-list > li .neon-cb-replacement.checked .cb-wrapper .checked {
  background: rgba(0, 0, 0, 0.25);
}
.tile-progress {
  background: #00a65b;
  margin-bottom: 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
  background-color: #303641;
  color: #ffffff;
}
.tile-progress:before,
.tile-progress:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.tile-progress:after {
  clear: both;
}
.tile-progress .tile-header {
  padding: 15px 20px;
  padding-bottom: 40px;
}
.tile-progress .tile-header h3,
.tile-progress .tile-header span {
  display: block;
  color: #ffffff;
}
.tile-progress .tile-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}
.tile-progress .tile-header span {
  margin-top: 4px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 11px;
}
.tile-progress .tile-progressbar {
  height: 2px;
  background: rgba(0, 0, 0, 0.18);
  margin: 0;
}
.tile-progress .tile-progressbar span {
  display: block;
  background: #ffffff;
  width: 0%;
  height: 100%;
  -moz-transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
  -o-transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
  -webkit-transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
  transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
}
.tile-progress .tile-footer {
  padding: 20px;
  text-align: right;
  background: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 0 0 3px 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 0 3px 3px;
  -moz-background-clip: padding;
  border-radius: 0 0 3px 3px;
  background-clip: padding-box;
}
.tile-progress .tile-footer h4,
.tile-progress .tile-footer > span {
  display: block;
  color: #ffffff;
}
.tile-progress .tile-footer h4 {
  font-size: 14px;
  margin: 0;
}
.tile-progress .tile-footer > span {
  margin-top: 4px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 11px;
}
.tile-progress .tile-header h3,
.tile-progress .tile-header a,
.tile-progress .tile-header span,
.tile-progress .tile-header i {
  color: #ffffff;
}
.tile-progress .tile-progressbar span {
  background: #ffffff;
}
.tile-progress .tile-footer h4,
.tile-progress .tile-footer > span {
  color: #ffffff;
}
.tile-progress .tile-footer > span {
  color: rgba(255, 255, 255, 0.7);
}
.tile-progress.tile-red {
  background-color: #f56954;
  color: #ffffff;
}
.tile-progress.tile-red .tile-header h3,
.tile-progress.tile-red .tile-header a,
.tile-progress.tile-red .tile-header span,
.tile-progress.tile-red .tile-header i {
  color: #ffffff;
}
.tile-progress.tile-red .tile-progressbar span {
  background: #ffffff;
}
.tile-progress.tile-red .tile-footer h4,
.tile-progress.tile-red .tile-footer > span {
  color: #ffffff;
}
.tile-progress.tile-red .tile-footer > span {
  color: rgba(255, 255, 255, 0.7);
}
.tile-progress.tile-green {
  background-color: #00a65a;
  color: #ffffff;
}
.tile-progress.tile-green .tile-header h3,
.tile-progress.tile-green .tile-header a,
.tile-progress.tile-green .tile-header span,
.tile-progress.tile-green .tile-header i {
  color: #ffffff;
}
.tile-progress.tile-green .tile-progressbar span {
  background: #ffffff;
}
.tile-progress.tile-green .tile-footer h4,
.tile-progress.tile-green .tile-footer > span {
  color: #ffffff;
}
.tile-progress.tile-green .tile-footer > span {
  color: rgba(255, 255, 255, 0.7);
}
.tile-progress.tile-blue {
  background-color: #0073b7;
  color: #ffffff;
}
.tile-progress.tile-blue .tile-header h3,
.tile-progress.tile-blue .tile-header a,
.tile-progress.tile-blue .tile-header span,
.tile-progress.tile-blue .tile-header i {
  color: #ffffff;
}
.tile-progress.tile-blue .tile-progressbar span {
  background: #ffffff;
}
.tile-progress.tile-blue .tile-footer h4,
.tile-progress.tile-blue .tile-footer > span {
  color: #ffffff;
}
.tile-progress.tile-blue .tile-footer > span {
  color: rgba(255, 255, 255, 0.7);
}
.tile-progress.tile-aqua {
  background-color: #00c0ef;
  color: #ffffff;
}
.tile-progress.tile-aqua .tile-header h3,
.tile-progress.tile-aqua .tile-header a,
.tile-progress.tile-aqua .tile-header span,
.tile-progress.tile-aqua .tile-header i {
  color: #ffffff;
}
.tile-progress.tile-aqua .tile-progressbar span {
  background: #ffffff;
}
.tile-progress.tile-aqua .tile-footer h4,
.tile-progress.tile-aqua .tile-footer > span {
  color: #ffffff;
}
.tile-progress.tile-aqua .tile-footer > span {
  color: rgba(255, 255, 255, 0.7);
}
.tile-progress.tile-cyan {
  background-color: #00b29e;
  color: #ffffff;
}
.tile-progress.tile-cyan .tile-header h3,
.tile-progress.tile-cyan .tile-header a,
.tile-progress.tile-cyan .tile-header span,
.tile-progress.tile-cyan .tile-header i {
  color: #ffffff;
}
.tile-progress.tile-cyan .tile-progressbar span {
  background: #ffffff;
}
.tile-progress.tile-cyan .tile-footer h4,
.tile-progress.tile-cyan .tile-footer > span {
  color: #ffffff;
}
.tile-progress.tile-cyan .tile-footer > span {
  color: rgba(255, 255, 255, 0.7);
}
.tile-progress.tile-purple {
  background-color: #ba79cb;
  color: #ffffff;
}
.tile-progress.tile-purple .tile-header h3,
.tile-progress.tile-purple .tile-header a,
.tile-progress.tile-purple .tile-header span,
.tile-progress.tile-purple .tile-header i {
  color: #ffffff;
}
.tile-progress.tile-purple .tile-progressbar span {
  background: #ffffff;
}
.tile-progress.tile-purple .tile-footer h4,
.tile-progress.tile-purple .tile-footer > span {
  color: #ffffff;
}
.tile-progress.tile-purple .tile-footer > span {
  color: rgba(255, 255, 255, 0.7);
}
.tile-progress.tile-pink {
  background-color: #ec3b83;
  color: #ffffff;
}
.tile-progress.tile-pink .tile-header h3,
.tile-progress.tile-pink .tile-header a,
.tile-progress.tile-pink .tile-header span,
.tile-progress.tile-pink .tile-header i {
  color: #ffffff;
}
.tile-progress.tile-pink .tile-progressbar span {
  background: #ffffff;
}
.tile-progress.tile-pink .tile-footer h4,
.tile-progress.tile-pink .tile-footer > span {
  color: #ffffff;
}
.tile-progress.tile-pink .tile-footer > span {
  color: rgba(255, 255, 255, 0.7);
}
.tile-progress.tile-orange {
  background-color: #ffa812;
  color: #ffffff;
}
.tile-progress.tile-orange .tile-header h3,
.tile-progress.tile-orange .tile-header a,
.tile-progress.tile-orange .tile-header span,
.tile-progress.tile-orange .tile-header i {
  color: #ffffff;
}
.tile-progress.tile-orange .tile-progressbar span {
  background: #ffffff;
}
.tile-progress.tile-orange .tile-footer h4,
.tile-progress.tile-orange .tile-footer > span {
  color: #ffffff;
}
.tile-progress.tile-orange .tile-footer > span {
  color: rgba(255, 255, 255, 0.7);
}
.tile-progress.tile-brown {
  background-color: #6c541e;
  color: #ffffff;
}
.tile-progress.tile-brown .tile-header h3,
.tile-progress.tile-brown .tile-header a,
.tile-progress.tile-brown .tile-header span,
.tile-progress.tile-brown .tile-header i {
  color: #ffffff;
}
.tile-progress.tile-brown .tile-progressbar span {
  background: #ffffff;
}
.tile-progress.tile-brown .tile-footer h4,
.tile-progress.tile-brown .tile-footer > span {
  color: #ffffff;
}
.tile-progress.tile-brown .tile-footer > span {
  color: rgba(255, 255, 255, 0.7);
}
.tile-progress.tile-plum {
  background-color: #701c1c;
  color: #ffffff;
}
.tile-progress.tile-plum .tile-header h3,
.tile-progress.tile-plum .tile-header a,
.tile-progress.tile-plum .tile-header span,
.tile-progress.tile-plum .tile-header i {
  color: #ffffff;
}
.tile-progress.tile-plum .tile-progressbar span {
  background: #ffffff;
}
.tile-progress.tile-plum .tile-footer h4,
.tile-progress.tile-plum .tile-footer > span {
  color: #ffffff;
}
.tile-progress.tile-plum .tile-footer > span {
  color: rgba(255, 255, 255, 0.7);
}
.tile-progress.tile-gray {
  background-color: #f5f5f5;
  color: #8f8f8f;
}
.tile-progress.tile-gray .tile-header h3,
.tile-progress.tile-gray .tile-header a,
.tile-progress.tile-gray .tile-header span,
.tile-progress.tile-gray .tile-header i {
  color: #8f8f8f;
}
.tile-progress.tile-gray .tile-progressbar span {
  background: #8f8f8f;
}
.tile-progress.tile-gray .tile-footer h4,
.tile-progress.tile-gray .tile-footer > span {
  color: #8f8f8f;
}
.tile-progress.tile-gray .tile-footer > span {
  color: rgba(143, 143, 143, 0.7);
}
.tile-group {
  background: #303641;
  color: #ffffff;
  margin-bottom: 20px;
  overflow: hidden;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  display: table;
  width: 100%;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
}
.tile-group:before,
.tile-group:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.tile-group:after {
  clear: both;
}
.tile-group .tile-left,
.tile-group .tile-right {
  display: table-cell;
  position: relative;
}
.tile-group .tile-left .map,
.tile-group .tile-right .map {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 1px 0;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
}
.tile-group .tile-left {
  width: 40%;
}
.tile-group .tile-right {
  width: 60%;
}
.tile-group .tile-entry {
  background: rgba(0, 0, 0, 0.1);
  padding: 25px 30px;
  border-bottom: 1px solid #303641;
}
.tile-group .tile-entry span,
.tile-group .tile-entry h3,
.tile-group .tile-entry h4 {
  display: block;
  color: #ffffff;
  margin: 0;
}
.tile-group .tile-entry span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 11px;
}
.tile-group .tile-entry h3 + span,
.tile-group .tile-entry h4 + span {
  margin-top: 4px;
}
.tile-group .tile-entry .op {
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  filter: alpha(opacity=50);
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.tile-group .tile-entry:hover .op {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}
.tile-group div > tile-entry:last-child {
  border-bottom: 0;
}
.tile-group .jvectormap-zoomin,
.tile-group .jvectormap-zoomout {
  width: 15px;
  height: 15px;
  margin-bottom: 4px;
}
.tile-group.tile-red {
  background-color: #ff4853;
  color: #ffffff;
}
.tile-group.tile-red .tile-entry {
  border-bottom-color: #ff4853;
}
.tile-group.tile-red .tile-entry h3,
.tile-group.tile-red .tile-entry h4,
.tile-group.tile-red .tile-entry a,
.tile-group.tile-red .tile-entry span,
.tile-group.tile-red .tile-entry i {
  color: #ffffff;
}
.tile-group.tile-red .tile-progressbar span {
  background: #ffffff;
}
.tile-group.tile-red .tile-footer h4,
.tile-group.tile-red .tile-footer > span {
  color: #ffffff;
}
.tile-group.tile-red .tile-footer > span {
  color: rgba(255, 255, 255, 0.7);
}
.tile-group.tile-green {
  background-color: #00a65a;
  color: #ffffff;
}
.tile-group.tile-green .tile-entry {
  border-bottom-color: #00a65a;
}
.tile-group.tile-green .tile-entry h3,
.tile-group.tile-green .tile-entry h4,
.tile-group.tile-green .tile-entry a,
.tile-group.tile-green .tile-entry span,
.tile-group.tile-green .tile-entry i {
  color: #ffffff;
}
.tile-group.tile-green .tile-progressbar span {
  background: #ffffff;
}
.tile-group.tile-green .tile-footer h4,
.tile-group.tile-green .tile-footer > span {
  color: #ffffff;
}
.tile-group.tile-green .tile-footer > span {
  color: rgba(255, 255, 255, 0.7);
}
.tile-group.tile-blue {
  background-color: #0073b7;
  color: #ffffff;
}
.tile-group.tile-blue .tile-entry {
  border-bottom-color: #0073b7;
}
.tile-group.tile-blue .tile-entry h3,
.tile-group.tile-blue .tile-entry h4,
.tile-group.tile-blue .tile-entry a,
.tile-group.tile-blue .tile-entry span,
.tile-group.tile-blue .tile-entry i {
  color: #ffffff;
}
.tile-group.tile-blue .tile-progressbar span {
  background: #ffffff;
}
.tile-group.tile-blue .tile-footer h4,
.tile-group.tile-blue .tile-footer > span {
  color: #ffffff;
}
.tile-group.tile-blue .tile-footer > span {
  color: rgba(255, 255, 255, 0.7);
}
.tile-group.tile-aqua {
  background-color: #00c0ef;
  color: #ffffff;
}
.tile-group.tile-aqua .tile-entry {
  border-bottom-color: #00c0ef;
}
.tile-group.tile-aqua .tile-entry h3,
.tile-group.tile-aqua .tile-entry h4,
.tile-group.tile-aqua .tile-entry a,
.tile-group.tile-aqua .tile-entry span,
.tile-group.tile-aqua .tile-entry i {
  color: #ffffff;
}
.tile-group.tile-aqua .tile-progressbar span {
  background: #ffffff;
}
.tile-group.tile-aqua .tile-footer h4,
.tile-group.tile-aqua .tile-footer > span {
  color: #ffffff;
}
.tile-group.tile-aqua .tile-footer > span {
  color: rgba(255, 255, 255, 0.7);
}
.tile-group.tile-cyan {
  background-color: #00b29e;
  color: #ffffff;
}
.tile-group.tile-cyan .tile-entry {
  border-bottom-color: #00b29e;
}
.tile-group.tile-cyan .tile-entry h3,
.tile-group.tile-cyan .tile-entry h4,
.tile-group.tile-cyan .tile-entry a,
.tile-group.tile-cyan .tile-entry span,
.tile-group.tile-cyan .tile-entry i {
  color: #ffffff;
}
.tile-group.tile-cyan .tile-progressbar span {
  background: #ffffff;
}
.tile-group.tile-cyan .tile-footer h4,
.tile-group.tile-cyan .tile-footer > span {
  color: #ffffff;
}
.tile-group.tile-cyan .tile-footer > span {
  color: rgba(255, 255, 255, 0.7);
}
.tile-group.tile-purple {
  background-color: #ba79cb;
  color: #ffffff;
}
.tile-group.tile-purple .tile-entry {
  border-bottom-color: #ba79cb;
}
.tile-group.tile-purple .tile-entry h3,
.tile-group.tile-purple .tile-entry h4,
.tile-group.tile-purple .tile-entry a,
.tile-group.tile-purple .tile-entry span,
.tile-group.tile-purple .tile-entry i {
  color: #ffffff;
}
.tile-group.tile-purple .tile-progressbar span {
  background: #ffffff;
}
.tile-group.tile-purple .tile-footer h4,
.tile-group.tile-purple .tile-footer > span {
  color: #ffffff;
}
.tile-group.tile-purple .tile-footer > span {
  color: rgba(255, 255, 255, 0.7);
}
.tile-group.tile-pink {
  background-color: #ec3b83;
  color: #ffffff;
}
.tile-group.tile-pink .tile-entry {
  border-bottom-color: #ec3b83;
}
.tile-group.tile-pink .tile-entry h3,
.tile-group.tile-pink .tile-entry h4,
.tile-group.tile-pink .tile-entry a,
.tile-group.tile-pink .tile-entry span,
.tile-group.tile-pink .tile-entry i {
  color: #ffffff;
}
.tile-group.tile-pink .tile-progressbar span {
  background: #ffffff;
}
.tile-group.tile-pink .tile-footer h4,
.tile-group.tile-pink .tile-footer > span {
  color: #ffffff;
}
.tile-group.tile-pink .tile-footer > span {
  color: rgba(255, 255, 255, 0.7);
}
.tile-group.tile-orange {
  background-color: #ffa812;
  color: #ffffff;
}
.tile-group.tile-orange .tile-entry {
  border-bottom-color: #ffa812;
}
.tile-group.tile-orange .tile-entry h3,
.tile-group.tile-orange .tile-entry h4,
.tile-group.tile-orange .tile-entry a,
.tile-group.tile-orange .tile-entry span,
.tile-group.tile-orange .tile-entry i {
  color: #ffffff;
}
.tile-group.tile-orange .tile-progressbar span {
  background: #ffffff;
}
.tile-group.tile-orange .tile-footer h4,
.tile-group.tile-orange .tile-footer > span {
  color: #ffffff;
}
.tile-group.tile-orange .tile-footer > span {
  color: rgba(255, 255, 255, 0.7);
}
.tile-group.tile-brown {
  background-color: #6c541e;
  color: #ffffff;
}
.tile-group.tile-brown .tile-entry {
  border-bottom-color: #6c541e;
}
.tile-group.tile-brown .tile-entry h3,
.tile-group.tile-brown .tile-entry h4,
.tile-group.tile-brown .tile-entry a,
.tile-group.tile-brown .tile-entry span,
.tile-group.tile-brown .tile-entry i {
  color: #ffffff;
}
.tile-group.tile-brown .tile-progressbar span {
  background: #ffffff;
}
.tile-group.tile-brown .tile-footer h4,
.tile-group.tile-brown .tile-footer > span {
  color: #ffffff;
}
.tile-group.tile-brown .tile-footer > span {
  color: rgba(255, 255, 255, 0.7);
}
.tile-group.tile-plum {
  background-color: #701c1c;
  color: #ffffff;
}
.tile-group.tile-plum .tile-entry {
  border-bottom-color: #701c1c;
}
.tile-group.tile-plum .tile-entry h3,
.tile-group.tile-plum .tile-entry h4,
.tile-group.tile-plum .tile-entry a,
.tile-group.tile-plum .tile-entry span,
.tile-group.tile-plum .tile-entry i {
  color: #ffffff;
}
.tile-group.tile-plum .tile-progressbar span {
  background: #ffffff;
}
.tile-group.tile-plum .tile-footer h4,
.tile-group.tile-plum .tile-footer > span {
  color: #ffffff;
}
.tile-group.tile-plum .tile-footer > span {
  color: rgba(255, 255, 255, 0.7);
}
.tile-group.tile-gray {
  background-color: #f5f5f5;
  color: #8f8f8f;
}
.tile-group.tile-gray .tile-entry {
  border-bottom-color: #f5f5f5;
}
.tile-group.tile-gray .tile-entry h3,
.tile-group.tile-gray .tile-entry h4,
.tile-group.tile-gray .tile-entry a,
.tile-group.tile-gray .tile-entry span,
.tile-group.tile-gray .tile-entry i {
  color: #8f8f8f;
}
.tile-group.tile-gray .tile-progressbar span {
  background: #8f8f8f;
}
.tile-group.tile-gray .tile-footer h4,
.tile-group.tile-gray .tile-footer > span {
  color: #8f8f8f;
}
.tile-group.tile-gray .tile-footer > span {
  color: rgba(143, 143, 143, 0.7);
}
.page-error-404 {
  color: #303641;
  text-align: center;
}
.page-error-404 .error-symbol {
  font-size: 120px;
}
.page-error-404 .error-text {
  padding-bottom: 25px;
  font-size: 16px;
}
.page-error-404 .error-text h2 {
  font-size: 45px;
}
.page-error-404 .error-text p {
  font-size: 22px;
}
.page-error-404 .error-text + hr {
  margin-bottom: 50px;
}
.page-error-404 .input-group {
  width: 250px;
  margin: 0 auto;
}
body.page-fade {
  background: #303641;
  -moz-transition: 400ms all ease-in-out;
  -o-transition: 400ms all ease-in-out;
  -webkit-transition: 400ms all ease-in-out;
  transition: 400ms all ease-in-out;
  -o-transform-origin: 50% 30%;
  -ms-transform-origin: 50% 30%;
  -webkit-transform-origin: 50% 30%;
  -moz-transform-origin: 50% 30%;
  transform-origin: 50% 30%;
}
body.page-fade > .page-container {
  -webkit-transform: scale(0.9) translateY(100px);
  -moz-transform: scale(0.9) translateY(100px);
  -o-transform: scale(0.9) translateY(100px);
  -ms-transform: scale(0.9) translateY(100px);
  transform: scale(0.9) translateY(100px);
  -moz-transition: 400ms all ease-in-out;
  -o-transition: 400ms all ease-in-out;
  -webkit-transition: 400ms all ease-in-out;
  transition: 400ms all ease-in-out;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
body.page-fade-init {
  background: #ffffff;
}
body.page-fade-init > .page-container {
  -webkit-transform: scale(1) translateY(0px);
  -moz-transform: scale(1) translateY(0px);
  -o-transform: scale(1) translateY(0px);
  -ms-transform: scale(1) translateY(0px);
  transform: scale(1) translateY(0px);
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}
body.page-left-in {
  background: #303641;
  -moz-transition: 400ms all ease-in-out;
  -o-transition: 400ms all ease-in-out;
  -webkit-transition: 400ms all ease-in-out;
  transition: 400ms all ease-in-out;
  -o-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  -webkit-transform-origin: 0% 50%;
  -moz-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  perspective: 1000px;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  perspective: 1000;
}
body.page-left-in > .page-container {
  -webkit-transform: rotateY(8deg) translateX(-100px);
  -moz-transform: rotateY(8deg) translateX(-100px);
  -o-transform: rotateY(8deg) translateX(-100px);
  -ms-transform: rotateY(8deg) translateX(-100px);
  transform: rotateY(8deg) translateX(-100px);
  -moz-transition: 400ms all ease-in-out;
  -o-transition: 400ms all ease-in-out;
  -webkit-transition: 400ms all ease-in-out;
  transition: 400ms all ease-in-out;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
body.page-left-in-init {
  background: #ffffff;
}
body.page-left-in-init > .page-container {
  -webkit-transform: rotateY(0deg) translateX(0px);
  -moz-transform: rotateY(0deg) translateX(0px);
  -o-transform: rotateY(0deg) translateX(0px);
  -ms-transform: rotateY(0deg) translateX(0px);
  transform: rotateY(0deg) translateX(0px);
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}
body.page-right-in {
  background: #303641;
  overflow-x: hidden;
  -moz-transition: 400ms all ease-in-out;
  -o-transition: 400ms all ease-in-out;
  -webkit-transition: 400ms all ease-in-out;
  transition: 400ms all ease-in-out;
  -o-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  -webkit-transform-origin: 100% 50%;
  -moz-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  perspective: 1000px;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  perspective: 1000;
}
body.page-right-in > .page-container {
  -webkit-transform: rotateY(-8deg) translateX(100px);
  -moz-transform: rotateY(-8deg) translateX(100px);
  -o-transform: rotateY(-8deg) translateX(100px);
  -ms-transform: rotateY(-8deg) translateX(100px);
  transform: rotateY(-8deg) translateX(100px);
  -moz-transition: 400ms all ease-in-out;
  -o-transition: 400ms all ease-in-out;
  -webkit-transition: 400ms all ease-in-out;
  transition: 400ms all ease-in-out;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
body.page-right-in-init {
  background: #ffffff;
}
body.page-right-in-init > .page-container {
  -webkit-transform: rotateY(0deg) translateX(0px);
  -moz-transform: rotateY(0deg) translateX(0px);
  -o-transform: rotateY(0deg) translateX(0px);
  -ms-transform: rotateY(0deg) translateX(0px);
  transform: rotateY(0deg) translateX(0px);
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}
body.page-fade-only {
  background: #303641;
  -moz-transition: 900ms all cubic-bezier(0.445, 0.050, 0.550, 0.950);
  -o-transition: 900ms all cubic-bezier(0.445, 0.050, 0.550, 0.950);
  -webkit-transition: 900ms all cubic-bezier(0.445, 0.050, 0.550, 0.950);
  transition: 900ms all cubic-bezier(0.445, 0.050, 0.550, 0.950);
  -o-transform-origin: 50% 30%;
  -ms-transform-origin: 50% 30%;
  -webkit-transform-origin: 50% 30%;
  -moz-transform-origin: 50% 30%;
  transform-origin: 50% 30%;
}
body.page-fade-only > .page-container {
  -moz-transition: 900ms all cubic-bezier(0.445, 0.050, 0.550, 0.950);
  -o-transition: 900ms all cubic-bezier(0.445, 0.050, 0.550, 0.950);
  -webkit-transition: 900ms all cubic-bezier(0.445, 0.050, 0.550, 0.950);
  transition: 900ms all cubic-bezier(0.445, 0.050, 0.550, 0.950);
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
body.page-fade-only-init {
  background: #ffffff;
}
body.page-fade-only-init > .page-container {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}
.draggable-portlets .sorted {
  min-height: 100px;
}
.draggable-portlets .sorted .ui-sortable-placeholder {
  background: rgba(255, 255, 204, 0.7);
  border: 1px dashed #ebebeb;
  visibility: visible !important;
}
.draggable-portlets .sorted > .panel {
  -webkit-transition: opacity 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
  -moz-transition: opacity 300ms ease-in-out, -moz-transform 300ms ease-in-out;
  -o-transition: opacity 300ms ease-in-out, -o-transform 300ms ease-in-out;
  transition: opacity 300ms ease-in-out,-webkit-transform 300ms ease-in-out,-moz-transform 300ms ease-in-out,-o-transform 300ms ease-in-out,transform 300ms ease-in-out;
  -webkit-transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
.draggable-portlets .sorted > .panel .panel-heading {
  cursor: pointer;
}
.draggable-portlets.dragging .sorted > .panel {
  -webkit-transform: scale(.9);
  -moz-transform: scale(.9);
  -o-transform: scale(.9);
  -ms-transform: scale(.9);
  transform: scale(.9);
  -webkit-opacity: 0.9;
  -moz-opacity: 0.9;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.draggable-portlets.dragging .sorted > .panel.ui-sortable-helper {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}
.language-selector {
  margin-top: -7px;
}
.language-selector > .dropdown-toggle {
  display: inline-block;
  padding: 7px 12px;
  -webkit-border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px;
  -moz-background-clip: padding;
  border-radius: 3px;
  background-clip: padding-box;
}
.language-selector > .dropdown-toggle img {
  display: inline-block;
  line-height: 1;
}
.language-selector.open > .dropdown-toggle {
  background: #f5f5f6;
}
.language-selector .dropdown-menu {
  background: #f5f5f6;
  border: none;
  margin: 0;
  padding: 0;
  width: 200px;
  overflow: hidden;
  -webkit-border-radius: 0 3px 3px 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 3px;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 3px;
  background-clip: padding-box;
}
.language-selector .dropdown-menu.pull-right {
  -webkit-border-radius: 3px 0 3px 3px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 0 3px 3px;
  -moz-background-clip: padding;
  border-radius: 3px 0 3px 3px;
  background-clip: padding-box;
  margin-right: 5px;
}
.language-selector .dropdown-menu > li {
  border-bottom: 1px solid #ebebeb;
}
.language-selector .dropdown-menu > li a {
  margin: 0;
  display: block;
  -webkit-border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0;
  -moz-background-clip: padding;
  border-radius: 0;
  background-clip: padding-box;
  padding: 10px 20px;
  color: #8d929a;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.language-selector .dropdown-menu > li a img {
  margin-right: 5px;
}
.language-selector .dropdown-menu > li a:hover {
  background: rgba(235, 235, 235, 0.4);
}
.language-selector .dropdown-menu > li:last-child {
  border-bottom: 0;
}
.language-selector .dropdown-menu > li.active a {
  background: rgba(235, 235, 235, 0.9);
  color: #737881;
}
.language-selector.open > .dropdown-toggle {
  -webkit-border-radius: 3px 3px 0 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 3px 3px 0 0;
  -moz-background-clip: padding;
  border-radius: 3px 3px 0 0;
  background-clip: padding-box;
}
.search-results-env .search-string {
  font-size: 17px;
}
.search-results-env .search-string strong {
  color: #ec5956;
  font-weight: normal;
}
.search-results-env .nav-tabs {
  margin-bottom: 0;
  border-bottom-color: #ebebeb;
}
.search-results-env .nav-tabs li a {
  background: #f5f5f6;
  border-color: #ebebeb;
}
.search-results-env .nav-tabs li.active a {
  background: #f9f9f9;
  border-bottom-color: transparent;
}
.search-results-env .search-bar {
  padding: 25px;
  background: #f9f9f9;
  margin-bottom: 30px;
}
.search-results-env .search-bar .form-control + .input-group-btn {
  border: 1px solid #303641;
  -webkit-border-radius: 0 3px 3px 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0 3px 3px 0;
  -moz-background-clip: padding;
  border-radius: 0 3px 3px 0;
  background-clip: padding-box;
}
.search-results-env .search-results-panes {
  position: relative;
}
.search-results-env .search-results-panes .search-results-pane {
  position: relative;
  display: none;
}
.search-results-env .search-results-panes .search-results-pane.active {
  display: block;
}
.search-results-env .search-results {
  list-style: none;
  margin: 0;
  padding: 0;
}
.search-results-env .search-results > .search-result {
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 20px;
  margin: 0;
  margin-bottom: 20px;
}
.search-results-env .search-results > .search-result h4 {
  font-size: 18px;
  margin: 0;
  margin-bottom: 10px;
}
.search-results-env .search-results > .search-result .link {
  color: #ec5956;
}
.search-results-env .search-results > .search-result .link:hover {
  text-decoration: underline;
}
.search-results-env .search-results > .search-result:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}
.search-results-env .search-results > .search-result .sr-inner {
  padding: 15px;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.search-results-env .search-results > .search-result .sr-inner:hover {
  background: #f9f9f9;
}
.search-results-env .search-results-messages thead tr th,
.search-results-env .search-results-messages tbody tr th,
.search-results-env .search-results-messages thead tr td,
.search-results-env .search-results-messages tbody tr td {
  padding: 10px 10px;
}
.search-results-env .search-results-messages tbody tr {
  background: #f9f9f9;
}
.search-results-env .search-results-messages tbody tr.unread {
  background: #FFF;
}
.search-results-env .search-results-messages tbody tr.unread > td:nth-child(2) a {
  font-weight: bold;
}
.search-results-env .search-results-messages tbody tr .star {
  display: inline-block;
  color: #dddddd;
  margin-right: 5px;
  font-size: 14px;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.search-results-env .search-results-messages tbody tr .star.stared,
.search-results-env .search-results-messages tbody tr .star.starred {
  color: #ff9600;
}
@media (max-width: 570px) {
  .search-results-env .nav-tabs {
    text-align: left;
  }
  .search-results-env .nav-tabs > li {
    float: none !important;
  }
  .search-results-env .nav-tabs > li.pull-left {
    display: block;
    width: 100%;
  }
  .search-results-env .nav-tabs > li .search-string {
    padding-bottom: 15px;
  }
}
.boxed-layout {
  background: url(../images/boxed-pattern-3.png);
  padding-bottom: 0px;
}
.boxed-layout.page-fade,
.boxed-layout.page-fade-only,
.boxed-layout.page-left-in,
.boxed-layout.page-right-in {
  background: url(../images/boxed-pattern-3.png);
}
.boxed-layout .page-container {
  position: relative;
  width: 80%;
  margin: 0 auto;
  margin-top: 0px;
  -moz-box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}
.boxed-layout .page-container:before,
.boxed-layout .page-container:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.boxed-layout .page-container:after {
  clear: both;
}
.boxed-layout .page-container.horizontal-menu > header.navbar {
  width: 80%;
  margin: 0 auto;
  -moz-box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}
.boxed-layout .page-container.horizontal-menu .main-content {
  padding-left: 0;
  padding-right: 0;
}
.boxed-layout .page-container.horizontal-menu .main-content .container {
  width: 100%;
}
.boxed-layout #chat {
  position: absolute;
}
@media screen and (max-width: 992px) {
  .boxed-layout .page-container {
    position: absolute;
    width: 100%;
    overflow-x: visible;
  }
  .boxed-layout #chat {
    position: fixed;
  }
}
@media (max-width: 1199px) {
  .pull-none-md {
    float: none !important;
  }
  .pull-none-md .dropdown-menu {
    left: 0;
    right: auto;
  }
  .pull-left-md {
    float: left !important;
  }
  .pull-left-md .dropdown-menu {
    left: 0;
    right: auto;
  }
  .pull-right-md {
    float: right !important;
  }
  .pull-right-md .dropdown-menu {
    left: auto;
    right: 0;
  }
}
@media (max-width: 991px) {
  .pull-none-sm {
    float: none !important;
  }
  .pull-none-sm .dropdown-menu {
    left: 0;
    right: auto;
  }
  .pull-left-sm {
    float: left !important;
  }
  .pull-left-sm .dropdown-menu {
    left: 0;
    right: auto;
  }
  .pull-right-sm {
    float: right !important;
  }
  .pull-right-sm .dropdown-menu {
    left: auto;
    right: 0;
  }
}
@media (max-width: 767px) {
  .pull-none-xs {
    float: none !important;
  }
  .pull-none-xs .dropdown-menu {
    left: 0;
    right: auto;
  }
  .pull-left-xs {
    float: left !important;
  }
  .pull-left-xs .dropdown-menu {
    left: 0;
    right: auto;
  }
  .pull-right-xs {
    float: right !important;
  }
  .pull-right-xs .dropdown-menu {
    left: auto;
    right: 0;
  }
}
@media (max-width: 480px - 140) {
  .pull-none-xsm {
    float: none !important;
  }
  .pull-none-xsm .dropdown-menu {
    left: 0;
    right: auto;
  }
  .pull-left-xsm {
    float: left !important;
  }
  .pull-left-xsm .dropdown-menu {
    left: 0;
    right: auto;
  }
  .pull-right-xsm {
    float: right !important;
  }
  .pull-right-xsm .dropdown-menu {
    left: auto;
    right: 0;
  }
}
