@font-face {
    font-family: GmarketSans;
    font-style: normal;
    font-weight: 300;
    src: local('GmarketSansLight'),
    url(./GmarketSansLight.ttf) format('truetype'),
    url(./GmarketSansLight.otf) format('opentype'),
    url(./GmarketSansLight.woff2) format('woff2'),  
	  url(./GmarketSansLight.woff) format('woff');
}

@font-face {
    font-family: GmarketSans;
    font-style: normal;
    font-weight: 500;
    src: local('GmarketSansMedium'),
    url(./GmarketSansMedium.ttf) format('truetype'),
    url(./GmarketSansMedium.otf) format('opentype'),
    url(./GmarketSansMedium.woff2) format('woff2'),  
	  url(./GmarketSansMedium.woff) format('woff');
}

@font-face {
    font-family: GmarketSans;
    font-style: normal;
    font-weight: 700;
    src: local('GmarketSansBold'),
    url(./GmarketSansBold.ttf) format('truetype'),
    url(./GmarketSansBold.otf) format('opentype'),
    url(./GmarketSansBold.woff2) format('woff2'),  
	  url(./GmarketSansBold.woff) format('woff');
}
