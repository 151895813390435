@import url(assets/fonts/GmarketSans/GmarketSans.css);
@import url(assets/fonts/NotoSansKR/notosans.css);

@font-face {
  font-family: "Noto Sans KR Light";
  src: url("./assets/fonts/NotoSansKR/woff/NotoSansKR-DemiLight-Hestia.woff");
}

@font-face {
  font-family: "Noto Sans KR SemiBold";
  src: url("./assets/fonts/NotoSansKR/woff/NotoSansKR-Medium-Hestia.woff");
}

body {
  margin: 0;
  font-family:
    "Pretendard Variable",
    Pretendard,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: "salt" on;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.excel-middle-span {
  width: 36px !important;
  float: none !important;
  text-align: center !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.excel-btn {
  float: none !important;
  margin-left: 8px;
}

a:not(.underline) {
  text-decoration: none !important;
}

.table {
  display: table;
}

table {
  color: #373e4a;
  word-break: keep-all;
}

.table thead th {
  vertical-align: middle !important;
}

.table tbody td {
  display: table-cell;
  position: relative;
  vertical-align: middle !important;
}

.panel-body {
  color: #373e4a;
}

.modal-body {
  font-weight: 500;
  font-size: 14px;
}
.btn {
  outline: none !important;
  font-weight: bold !important;
}

.btn-default,
.btn-white {
  font-weight: 500 !important;
}

.form-control[disabled] {
  border-top: 0px !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: #303641 !important;
}

#root {
  position: relative;
  min-height: 100%;
}
.modal {
  background-color: rgba(0, 0, 0, 0.47);
  -webkit-animation-name: modalFade;
  animation-name: modalFade;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

.label-secondary {
  background-color: #ec5956;
}

.babi-btn {
  font-size: 15px;
  border: 1px solid #aaaaaa;
  color: #aaaaaa;
  display: inline-block;
  width: 50px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  padding-top: 1px;
  padding-bottom: 1px;
  line-height: 30px;
}

.babi-btn.active {
  border: 1px solid #7a69ff;
  color: #7a69ff;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 14px;
  left: 0;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-track-piece {
  background-color: transparent;
  -webkit-border-radius: 8px;
}
::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #aaaaaa;
}
::-webkit-scrollbar-thumb:hover {
  border: 3px solid rgba(0, 0, 0, 0);
  background-color: #9e9e9e;
}
::-webkit-scrollbar-button {
  display: none;
}

.textColor-tint {
  color: #ff508c !important;
}

form input.error,
form textarea.error,
form .mui_input.error,
form .MuiInputBase-root.error,
form .thumbnail.error,
form .checkbox.error .cb-wrapper,
form .date_picker_input.error,
form .error .rc-time-picker-input,
form .react-select.error div[class$="control"] {
  border: 1px solid #fb545e !important;
  color: #f82d3e;
}

b,
span,
li,
p,
div {
  & [color="babi700"] {
    color: #7264ff;
  }
  & [color="babi600"] {
    color: #897dff;
  }

  & [color="gray800"] {
    color: #484760;
  }
  & [color="gray500"] {
    color: #a3a3af;
  }

  & [color="pink"] {
    color: #ff508c;
  }

  & [color="red"] {
    color: #f82d3e;
  }
}
